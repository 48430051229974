import React, { useCallback, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import moment from 'moment';
import FormSignatureCanvas from './FormSignatureCanvas';
import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { FieldValidator } from 'final-form';
import { StyleSheet, css } from 'aphrodite';
import { Typography } from '@mui/material';

interface FormSignatureInputProps {
  name: string;
  label: string;
  validate?: FieldValidator<string>;
}

export const FORM_DATE_FORMAT = 'MM/DD/YYYY';

const FormSignature: React.FC<FormSignatureInputProps> = ({ name, label, validate }) => {
  const [date, setDate] = useState('');
  const signatureBox = useRef<HTMLDivElement>(null);
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <div className={css(FormSignatureStyles.container)}>
          <Typography variant="h3">{label}</Typography>
          <div
            className={css(FormSignatureStyles.signatureBox)}
            style={{
              borderColor: GrayscalePalette[300],
            }}
          >
            <div
              style={{
                borderColor: GrayscalePalette[300],
              }}
              ref={signatureBox}
            >
              <FormSignatureCanvas
                meta={meta}
                onChange={useCallback(
                  (val) => {
                    const signatureDate = moment().format(FORM_DATE_FORMAT);
                    setDate(signatureDate);
                    input.onChange({
                      date: signatureDate,
                      signature: val,
                    });
                  },
                  [input],
                )}
                onClear={() => input.onChange(null)}
                parent={signatureBox}
              />
            </div>
          </div>
          <Typography variant="subtitle1" className={css(FormSignatureStyles.signatureDateText)}>
            Date of Signature: {date}
          </Typography>
        </div>
      )}
    </Field>
  );
};

const FormSignatureStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  signatureBox: {
    borderRadius: '16px',
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  signatureDateText: {
    color: GrayscalePalette[600],
    textAlign: 'right',
  },
});

export default FormSignature;
