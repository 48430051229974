import moment from 'moment';
import { Eta, EtaStatus } from '@tempus/order-status-service-shared';
import { AllReportStatuses } from '@tempus/portal-service-shared';

const formatEta = (eta?: Eta, reportStatus?: AllReportStatuses, isQns?: boolean) => {
  if (reportStatus === AllReportStatuses.DELAYED) return 'Delayed';

  if (!eta) return 'Not Available';

  if (eta.status === EtaStatus.COMPLETE) {
    return isQns ? 'Delivered (Incomplete Report)' : 'Delivered';
  }
  if (eta.status === EtaStatus.PENDING) return 'Pending';
  if (eta.status === EtaStatus.DELAYED) return 'Delayed';
  if (eta.status === EtaStatus.UNAVAILABLE || eta.status === EtaStatus.UNSUPPORTED) return 'Not Available';

  if (eta.optimisticDate && eta.pessimisticDate) {
    return moment(eta.optimisticDate).utc().format('MMM D') + ' - ' + moment(eta.pessimisticDate).utc().format('MMM D');
  }

  return 'Not Available';
};

export default formatEta;
