import { Grid, Typography } from '@mui/material';
import { css, StyleSheet } from 'aphrodite';
import { FC } from 'react';

interface InfoItemProps {
  itemName: string;
}

const InfoItem: FC<InfoItemProps> = (props) => {
  return (
    <Grid className={css(PatientInfoItemStyles.container)}>
      <Typography variant="h3">{props.itemName}</Typography>
      <Grid>{props.children}</Grid>
    </Grid>
  );
};

export default InfoItem;

const PatientInfoItemStyles = StyleSheet.create({
  container: {
    marginBottom: '18px',
  },
});
