import { Grid } from '@mui/material';
import { ErrorPalette, ProgressPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { Fail, Success, Warning } from '@tcl-boron-icons/icons';
import { PhaseStatus } from '@tempus/order-status-service-shared';
import { AllReportStatuses } from '@tempus/portal-service-shared';

const NodeIcon: React.FC<{ itemStatus: PhaseStatus; reportStatus?: AllReportStatuses }> = ({
  itemStatus,
  reportStatus,
}) => {
  if (itemStatus === PhaseStatus.COMPLETE) {
    return <Success height="16px" color={ProgressPalette[700]} />;
  }

  if (itemStatus === PhaseStatus.FAILED) {
    return <Fail height="16px" color={ErrorPalette[700]} />;
  }

  if (itemStatus === PhaseStatus.IN_PROGRESS && reportStatus === AllReportStatuses.DELAYED) {
    return <Warning height="16px" color={WarningPalette[700]} />;
  }

  return <Grid item height="16px" width="16px" borderRadius="8px" border={`3px solid ${ProgressPalette[700]}`} />;
};

export default NodeIcon;
