import { Grid, Typography } from '@mui/material';
import { SuccessPalette } from '@tcl-boron-colors/colors';
import { Success } from '@tcl-boron-icons/icons';
import { Card } from '@tcl-boron-prefabs/card';

const PaidCard: React.FC<{ amount: number }> = ({ amount }) => {
  return (
    <Card style={{ height: 'auto', padding: '24px' }} data-testid="paid-card">
      <Grid container direction="column" gap="20px">
        <Grid item container alignItems="center" gap="8px">
          <Success color={SuccessPalette[700]} />
          <Typography variant="h1">Paid with card</Typography>
        </Grid>
        <Typography variant="body1" fontWeight={600} letterSpacing="2px">
          PAID: ${amount}
        </Typography>
      </Grid>
    </Card>
  );
};

export default PaidCard;
