import { Grid, Typography } from '@mui/material';
import { AccentPalette } from '@tcl-boron-colors/colors';
import { FC } from 'react';
import { Button } from '@tcl-boron-prefabs/button';
import usePortalContext from '../../../context/usePortalContext';
import { css, StyleSheet } from 'aphrodite';
import { AddSimple } from '@tcl-boron-icons/icons';

const AddInsuranceButton: FC = () => {
  const { setIsFormEditOpen } = usePortalContext();
  const edit = () => {
    setIsFormEditOpen(true);
  };

  return (
    <Grid item container width="auto">
      <Button
        buttonType="tertiary"
        onClick={edit}
        small={true}
        ariaLabel="Add Insurance"
        classes={{ root: css(AddInsuranceButtonStyles.root) }}
        data-testid="add-insurance-button"
      >
        <Grid container gap="8px" color={AccentPalette[100]} justifyContent="center" alignItems="center">
          <AddSimple height={16} width={16} />
          <Typography variant="button">Add Insurance</Typography>
        </Grid>
      </Button>
    </Grid>
  );
};

export default AddInsuranceButton;

const AddInsuranceButtonStyles = StyleSheet.create({
  root: {
    display: 'flex',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
