import { PortalViewsEnum, ReportStatusesEnum, ReportTypes } from '@tempus/portal-service-shared';
import fetchHelper, { RequestOptions } from '../../utils';
import { createContext } from 'react';
import OnboardingInfo from './interfaces/onboarding-status';

export interface PortalReport {
  id: string;
  label: string;
  type: ReportTypes;
  status: ReportStatusesEnum;
  isQns: boolean;
  createdAt: Date;
}

export interface PortalPatient {
  patientId: string;
  orderId: string;
  toggles?: PortalToggles;
  reports: PortalReport[];
}

export interface PortalToggles {
  hidePaymentDetails?: boolean;
}

export interface IPortalContext {
  loading: boolean;
  error: boolean;
  selectedPortalView: PortalViewsEnum;
  patient: PortalPatient;
  onboardingInfo?: OnboardingInfo;
  setOnboardingInfo: (info: OnboardingInfo) => void;
  isFormEditOpen: boolean;
  setIsFormEditOpen: (isOpen: boolean) => void;
  fetchHelper: <T>(path: string, options?: RequestOptions) => Promise<T>;
}

const stub = (): never => {
  throw new Error('Not implemented');
};

export const initialPortalContext: IPortalContext = {
  loading: false,
  error: false,
  selectedPortalView: PortalViewsEnum.PSYCHIATRY,
  patient: {
    patientId: '',
    orderId: '',
    reports: [],
  },
  setOnboardingInfo: stub,
  isFormEditOpen: false,
  setIsFormEditOpen: stub,
  fetchHelper,
};

const PortalContext = createContext<IPortalContext>(initialPortalContext);

export default PortalContext;
