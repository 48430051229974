import { createContext, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { Banner } from '@tcl-boron-prefabs/banner';
import { Fail, Success } from '@tcl-boron-icons/icons';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface INotification {
  type: NotificationType;
  message: string;
  intervalId: NodeJS.Timeout;
}

export interface INotificationContext {
  notify: (type: NotificationType, message: string, timeout?: number) => void;
}

const stub = (): never => {
  throw new Error('Not implemented');
};

export const initialNotificationContext: INotificationContext = {
  notify: stub,
};

export const NotificationContext = createContext<INotificationContext>(initialNotificationContext);

export const NotificationContextProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<INotification[]>([]);

  const notify = (type: NotificationType, message: string, timeout = 3000) => {
    const intervalId = setTimeout(() => {
      setMessages((prevMessages) => prevMessages.filter((m) => m.intervalId !== intervalId));
    }, timeout);

    setMessages((prevMessages) => [...prevMessages, { type, message, intervalId }]);
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <Grid
        container
        direction="column"
        gap="8px"
        alignItems="stretch"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        padding="16px"
      >
        {messages.map((m, idx) => (
          <Banner
            key={idx}
            status={m.type}
            ariaLabel={m.message}
            message={m.message}
            Icon={m.type === NotificationType.SUCCESS ? Success : Fail}
          />
        ))}
      </Grid>
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
