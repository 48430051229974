import React from 'react';
import { PortalContainer } from '../../components/PortalContainer';
import Grid from '@mui/material/Grid';
import ScreenHeader from '../../components/ScreenHeader';
import { Typography } from '@mui/material';
import { Button } from '@tcl-boron-prefabs/button';
import { useHistory } from 'react-router-dom';

const KitReturn: React.FC = () => {
  const { push } = useHistory();

  return (
    <PortalContainer>
      <Grid container direction="column" flexGrow={1}>
        <ScreenHeader text="Kit Return Options" />
        <Typography variant="body1">
          Returning your kit quickly is important to avoid delays in your PGx report being generated. You have two
          options to return:
        </Typography>
        <Grid
          container
          direction="column"
          gap="30px"
          paddingY="30px"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <a href="http://tempus.com/ship" target="_blank" rel="noreferrer">
            <Button
              buttonType="primary"
              ariaLabel="Schedule a FedEx Pickup"
              minWidth="250px"
              data-testid="schedule-fedex-pickup-button"
            >
              Schedule a FedEx Pickup
            </Button>
          </a>
          <a href="https://local.fedex.com/en-us" target="_blank" rel="noreferrer">
            <Button
              buttonType="primary"
              ariaLabel="Find a FedEx Drop-off Location"
              minWidth="250px"
              data-testid="find-fedex-dropoff-button"
            >
              Find a FedEx Drop-off Location
            </Button>
          </a>
        </Grid>
        <Grid item container padding="30px 0px" justifyContent="center">
          <Button
            buttonType="tertiary"
            ariaLabel="Back"
            minWidth="150px"
            onClick={() => push('/')}
            data-testid="back-button"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </PortalContainer>
  );
};

export default KitReturn;
