import React, { useEffect, useState, useCallback } from 'react';
import { StyleSheet, css, StyleDeclarationValue } from 'aphrodite/no-important';
import { Overlay as NavbarMenu } from '@tcl-argon-prefabs/overlay';
import { Menu as MenuIcon, Close } from '@tcl-argon-icons/icons';
import { Tooltip } from '@tcl-argon-prefabs/tooltip';
import { primaryPalette } from '@tcl-argon-colors/colors';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { Breakpoints, styles, fonts } from '../../../constants/styles';
import { isMobileDevice, useComponentVisible } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { NavigationPath } from '../../../api/constants';
import HelpModal, { HelpContent } from './HelpModal';
import Config from '../../../../config';

enum NAVBAR_TABS {
  HOME = 'Home',
  ACCOUNT = 'Account',
  HELP = 'Help',
}
const HELP_BUTTON_TEXT = 'Help';
const MENU_BURGER_ICON = 'menu-burger-icon';
const MENU_CLOSE_ICON = 'menu-close-icon';
const MENU_TEST_ID = 'menu-test-id';

interface MenuItem {
  name: string;
  onClick: () => void;
}

interface MenuItemProps extends MenuItem {
  styles?: (StyleDeclarationValue | undefined)[];
}

interface NavbarMenuItem extends MenuItem {
  path?: NavigationPath;
}

const NavbarMenuItem = (props: MenuItemProps) => {
  return (
    <div onClick={props.onClick} className={css(styles.bodyText, props.styles)}>
      {props.name}
    </div>
  );
};

const VerifiedUserTabs = (): JSX.Element => {
  const { push: navigate, location } = useHistory();
  const { ref, isComponentVisible: isHelpPromptOpen, setIsComponentVisible: setHelpPrompt } = useComponentVisible(
    false,
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const window = useWindowDimensions();

  const navbarMenuItems: NavbarMenuItem[] = [
    { name: NAVBAR_TABS.HOME, onClick: () => navigate(NavigationPath.ORDERS), path: NavigationPath.ORDERS },
    { name: NAVBAR_TABS.ACCOUNT, onClick: () => navigate(NavigationPath.ACCOUNT), path: NavigationPath.ACCOUNT },
    { name: NAVBAR_TABS.HELP, onClick: () => setHelpPrompt(!isHelpPromptOpen) },
  ];

  const NavbarMenuIcon = useCallback(() => {
    return isMenuOpen ? (
      <Close data-testid={MENU_CLOSE_ICON} onClick={navbarMenuIconClick} />
    ) : (
      <MenuIcon data-testid={MENU_BURGER_ICON} onClick={navbarMenuIconClick} />
    );
  }, [isMenuOpen]);

  const navbarMenuIconClick = () => {
    setIsMenuOpen(!isMenuOpen);
    setHelpPrompt(false);
  };

  const mobileMenuItemClick = (item: NavbarMenuItem) => {
    item.onClick();
    setIsMenuOpen(false);
  };

  useEffect(() => {
    //prevents background from scrolling when sideMenu is open
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  const MobileNavbarMenu = () => {
    const isTestEnvironment = Config.environment === 'test';

    return (
      <>
        <NavbarMenuIcon />
        <NavbarMenu
          ariaHideApp={!isTestEnvironment}
          isOpen={isMenuOpen}
          className={css(VerifiedUserTabsStyles.overlay)}
          classes={{
            closeButton: css(VerifiedUserTabsStyles.overlayCloseButton),
            overlay: css(VerifiedUserTabsStyles.overlayBackground),
          }}
        >
          {navbarMenuItems.map((item, i) => {
            const isActive = location.pathname === item.path;
            return (
              <NavbarMenuItem
                key={i}
                onClick={() => mobileMenuItemClick(item)}
                name={item.name}
                styles={[
                  VerifiedUserTabsStyles.menuItem,
                  isActive ? [fonts.ibmPlexSansMedium, VerifiedUserTabsStyles.activeMenuItem] : undefined,
                ]}
              />
            );
          })}
        </NavbarMenu>
        <HelpModal isOpen={isHelpPromptOpen} />
      </>
    );
  };

  const WebNavbarTabs = () => (
    <div className={css(VerifiedUserTabsStyles.navbarItemsContainer)}>
      {navbarMenuItems.map((item, idx) => {
        const isActive = location.pathname === item.path;

        if (item.name === NAVBAR_TABS.HELP) {
          return (
            <Tooltip key={idx} isOpen={isHelpPromptOpen} position="below-left">
              {{
                tooltip: <HelpContent />,
                base: <NavbarMenuItem onClick={item.onClick} name={item.name} />,
              }}
            </Tooltip>
          );
        }
        return (
          <NavbarMenuItem
            key={idx}
            onClick={item.onClick}
            name={item.name}
            styles={[VerifiedUserTabsStyles.navbarTab, isActive ? [fonts.ibmPlexSansMedium] : undefined]}
          />
        );
      })}
    </div>
  );

  return (
    <div ref={ref} className={css(VerifiedUserTabsStyles.container)}>
      {isMobileDevice(window) ? <MobileNavbarMenu /> : <WebNavbarTabs />}
    </div>
  );
};

export default VerifiedUserTabs;
export { HELP_BUTTON_TEXT, MENU_TEST_ID, MENU_BURGER_ICON, MENU_CLOSE_ICON };

const VerifiedUserTabsStyles = StyleSheet.create({
  activeMenuItem: {
    borderLeft: `4px solid ${primaryPalette.tempusPrimary}`,
    padding: '0px 15px',
  },
  menuItem: {
    padding: '0px 20px',
    marginBottom: '20px',
  },
  navbarTab: {
    marginRight: '40px',
  },
  overlayBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    inset: '60px 0px',
  },
  overlayCloseButton: {
    display: 'none',
  },
  overlay: {
    left: 'unset',
    right: '0px',
    top: '60px',
    transform: 'initial',
    width: '50%',
    padding: '20px 0px',
  },
  container: {
    cursor: 'pointer',
    marginRight: '2.14em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      marginRight: '0px',
    },
  },
  navbarItemsContainer: {
    display: 'flex',
  },
});
