import { useHistory } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import { Grid, Typography } from '@mui/material';
import ScreenHeader from '../../components/ScreenHeader';
import { NPP_URL, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../shared/constants';
import withPortalContainer from '../../hooks/withPortalContainer';
import { NavigationPath } from '../../../api/constants';
import usePortalContext from '../../context/usePortalContext';

const TermsOfUse: React.FC<{ actionOverride?: () => Promise<void> }> = ({ actionOverride }) => {
  const { push } = useHistory();
  const { fetchHelper } = usePortalContext();

  const onSubmit = async () => {
    const response = await fetchHelper<{ isAccepted: boolean }>('/terms-of-use/accept', {
      method: 'POST',
    });
    if (!response.isAccepted) {
      throw new Error('Accept terms of use endpoint in PWS returned isAccepted false - something went wrong');
    }

    if (actionOverride) {
      await actionOverride();
    } else {
      push(NavigationPath.PORTAL);
    }
  };

  return (
    <Grid container direction="column" flexGrow={1} gap="30px">
      <ScreenHeader text={'Terms of Use'} />
      <Grid container item direction="column" flexGrow={1} gap="30px">
        <Typography variant="body1">
          In order to use the patient portal, please review the following terms and indicate your agreement using the
          button below:
        </Typography>

        <Typography variant="body1">
          <a href={TERMS_OF_USE_URL} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          ,{' '}
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          , and{' '}
          <a href={NPP_URL} target="_blank" rel="noreferrer">
            Notice of Privacy Practices
          </a>
        </Typography>
      </Grid>
      <Grid item container padding="30px 0px" justifyContent="center">
        <Button minWidth="150px" ariaLabel={'I agree'} onClick={onSubmit}>
          <Typography variant="button">I agree</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withPortalContainer(TermsOfUse);

export const OnboardingTermsOfUse: React.FC<{ next?: () => Promise<void> }> = ({ next }) => (
  <TermsOfUse actionOverride={next} />
);
