import { RequiredItemTypeEnum } from '@tempus/portal-service-shared';
import { NavigationPath } from '../../../api/constants';
import { PhaseStatus } from '@tempus/order-status-service-shared';

export const MISSING_ITEM_PATIENT_FIELDS = [
  RequiredItemTypeEnum.SAMPLE_RECEIVED,
  RequiredItemTypeEnum.INSURANCE_INFORMATION,
  RequiredItemTypeEnum.BILLING_CONFIRMATION,
  RequiredItemTypeEnum.PAYMENT_RECEIVED,
];

export const MISSING_ITEM_PROVIDER_FIELDS = [
  RequiredItemTypeEnum.PATIENT_MEDICATIONS,
  RequiredItemTypeEnum.ICD_10_CODE,
  RequiredItemTypeEnum.PROVIDER_NPI,
  RequiredItemTypeEnum.PROVIDER_SIGNATURE,
];

export type MissingItemConfiguration = {
  buttonText: string;
  navigationPath: NavigationPath;
};

export const PATIENT_MOI_ALERT_CONFIG: Partial<Record<RequiredItemTypeEnum, MissingItemConfiguration>> = {
  [RequiredItemTypeEnum.SAMPLE_RECEIVED]: {
    buttonText: 'Return sample kit',
    navigationPath: NavigationPath.KIT_RETURN,
  },
  [RequiredItemTypeEnum.INSURANCE_INFORMATION]: {
    buttonText: 'Update insurance information',
    navigationPath: NavigationPath.PAYMENT_DETAILS,
  },
  [RequiredItemTypeEnum.BILLING_CONFIRMATION]: {
    buttonText: 'Confirm payment method',
    navigationPath: NavigationPath.PAYMENT_DETAILS,
  },
  [RequiredItemTypeEnum.PAYMENT_RECEIVED]: {
    buttonText: 'Make a payment',
    navigationPath: NavigationPath.PAYMENT_DETAILS,
  },
};

export const COMPLETE_STATUSES = [PhaseStatus.COMPLETE, PhaseStatus.FAILED];
