import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { primaryPalette } from '@tcl-argon-colors/colors';
import { Button } from '@tcl-argon-prefabs/button';
import { Warning } from '@tcl-argon-icons/icons';
import { InputTheme } from '@tcl-argon-models/models';
import { styles as textStyles } from '../../../constants/styles';
import ScreenLayout from './ScreenLayout';
import { HeaderType } from '../header/headerType';
import { logOutAndClearStorage, OktaSignOutState } from '../../../api/Okta';
import { OktaAuthNavigationPath } from '../../../api/constants';
import { useHistory } from 'react-router-dom';

const SERVICE_UNAVAILABLE_HEADER_TEXT = 'System Outage';
const SERVICE_UNAVAILABLE_MESSAGES = [
  'Our app is currently undergoing maintenence. Please check back later.',
  'You may also reach out to your ordering healthcare provider for your results.',
];
const SIGN_OUT = 'Sign out';

type SearchUnavailableProps = {
  title?: string;
  message?: string;
};

const ServiceUnavailable = (props: SearchUnavailableProps) => {
  const { push: navigate } = useHistory();

  return (
    <ScreenLayout header={HeaderType.UNAUTHENTICATED_HEADER}>
      <div className={css(styles.screen, textStyles.bodyText)}>
        <div className={css(styles.flexContainer)}>
          <div className={css(styles.content)}>
            <div className={css(styles.header)}>
              <Warning className={css(styles.warning)} />
              <p className={css(styles.headerText, textStyles.sectionHeaderBlack)}>
                {props.title ?? SERVICE_UNAVAILABLE_HEADER_TEXT}
              </p>
            </div>
            {props.message ??
              SERVICE_UNAVAILABLE_MESSAGES.map((message, index) => {
                return (
                  <p className={css(styles.message)} key={index}>
                    {message}
                  </p>
                );
              })}
            <Button
              data-testid="error-signout"
              className={css(styles.button)}
              onClick={() =>
                logOutAndClearStorage(OktaSignOutState.SIGNED_OUT).catch(() => navigate(OktaAuthNavigationPath.LOGIN))
              }
              theme={InputTheme.Dark}
            >
              <p className={css(styles.text)}>{SIGN_OUT}</p>
            </Button>
          </div>
        </div>
        <div className={css(styles.flexPadding)}></div>
      </div>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#000000',
    display: 'flex',
    maxWidth: '21.42em',
    justifyContent: 'center',
  },
  text: {
    margin: 0,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '2.14em',
    maxWidth: '25.64em',
    textAlign: 'center',
  },
  flexContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  flexPadding: {
    display: 'flex',
    flex: 1,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2.14em',
    textTransform: 'uppercase',
  },
  headerText: {
    margin: '0',
  },
  message: {
    marginBottom: '2.14em',
    marginTop: '0',
  },
  warning: {
    color: primaryPalette.errorPrimary,
    height: '1.07em',
    marginRight: '0.71em',
    width: '1.07em',
  },
  screen: {
    background: 'linear-gradient(241.5deg, #FFFFFF 0%, #F5F5F5 100%)',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
});

export default ServiceUnavailable;
export { SERVICE_UNAVAILABLE_HEADER_TEXT };
