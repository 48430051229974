import React from 'react';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import config from '../../../../config';
import PortalContextProvider from '../../../portal/context/PortalContextProvider';

const Auth0Wrapper = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState | undefined) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      authorizationParams={{ redirect_uri: window.location.origin, audience: config.patientWebServiceBaseUrl }}
      onRedirectCallback={onRedirectCallback}
    >
      <PortalContextProvider>{children}</PortalContextProvider>
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
