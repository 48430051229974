import { RequiredItemTypeEnum } from '@tempus/portal-service-shared';
import { PhaseStatus } from '@tempus/order-status-service-shared';

export enum PortalRequiredItemStatus {
  REQUIRED = 'required',
  UNDER_REVIEW = 'under-review',
  RECEIVED = 'received',
  REQUESTED = 'requested',
}

export type RequiredItem = {
  type: RequiredItemTypeEnum;
  status: PortalRequiredItemStatus;
  subItems: RequiredItem[] | null;
  displayText: string;
  subText: string;
};

export type MissingOrderItems = { patient: RequiredItem[]; provider: RequiredItem[] };

export type TimelineItem = {
  label: string;
  subLabel: string;
  status: PhaseStatus;
  date: string;
  isTerminalNode: boolean;
};
