import { Card } from '@tcl-boron-prefabs/card';
import { Grid, Typography } from '@mui/material';
import { Button } from '@tcl-boron-prefabs/button';

type SelfPayCardProps = {
  setPaymentNoticeOpen: (open: boolean) => void;
  payNowLink?: string;
};

export const SelfPayCard: React.FC<SelfPayCardProps> = ({ setPaymentNoticeOpen, payNowLink }) => {
  return (
    <Card style={{ height: 'auto', padding: '24px' }}>
      <Grid container direction="column" gap="20px">
        <Typography variant="h1">Paying with a card</Typography>
        <Typography variant="body1">
          You can pay now or later, but payment is required for us to deliver your report.
        </Typography>
        <Typography variant="body1" fontWeight={600} letterSpacing="2px">
          AMOUNT YOU OWE: $295
        </Typography>
        <Grid item container justifyContent="center" alignSelf="center">
          <a href={payNowLink} target="_blank" rel="noreferrer">
            <Button
              minWidth="150px"
              ariaLabel={'Pay Now'}
              buttonType="secondary"
              style={{ alignSelf: 'center' }}
              data-testid="pay-now-button"
              onClick={() => {
                setPaymentNoticeOpen(true);
              }}
            >
              <Typography variant="button">Pay Now</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
    </Card>
  );
};
