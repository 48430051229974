import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import ScreenHeader from '../../components/ScreenHeader';

const ReviewPatientInfoHeader: FC = () => {
  return (
    <Grid container direction="column">
      <ScreenHeader text={'Contact Information'} />
      <Typography variant="subtitle1">
        Please review and edit your contact details submitted by your clinician. If your name or date of birth is
        incorrect, email us at help@tempus.com.
      </Typography>
    </Grid>
  );
};

export default ReviewPatientInfoHeader;
