import { NavigationPath } from '../../../api/constants';
import { PortalToggles } from '../../context/PortalContext';

const isPathAllowed = (path?: string, toggles?: PortalToggles): boolean => {
  switch (path) {
    case NavigationPath.PAYMENT_DETAILS:
      return !toggles?.hidePaymentDetails;
    default:
      return true;
  }
};

export default isPathAllowed;
