import { css, StyleSheet } from 'aphrodite/no-important';
import React, { Fragment, FunctionComponent } from 'react';
import { styles as textStyles } from '../../../constants/styles';
import AppStoreButton from '../../../../images/appStoreButton.svg';
import GooglePlayButton from '../../../../images/googlePlayButton.png';
import Card from '@tcl-argon-prefabs/card';
import { ChevronRight } from '@tcl-argon-icons/icons';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import { getHipaaConsentForm, usePatientInfo } from '../../../api/PatientInfo';
import { useHistory } from 'react-router-dom';
import { NavigationPath } from '../../../api/constants';

type UserContentContainer = {
  header: string;
  text: string;
  links: React.ComponentType;
};

const appleStoreAltText = 'Apple App Store';
const googlePlayAltText = 'Google Play Store';
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.tempusid';
const appleStoreLink = 'https://apps.apple.com/us/app/tempus-covid-19/id1511865908';

const THANK_YOU_HEADER = 'Thank you for contributing to our research';
const THANK_YOU_TEXT =
  'Tempus is creating a database of de-identified COVID-19 patients in order to advance research and treatment for COVID-19 and other diseases. View your signed HIPAA Authorization below';

const CONTRIBUTE_HEADER = 'Contribute to our research';
const CONTRIBUTE_TEXT =
  'Help our research by finalizing your consent form! Please provide contact information for your primary care provider. This can be any healthcare provider you see regularly.';
const PCP_LINK = 'Fill out PCP contact form';

const DOWNLOAD_HEADER = 'Download the Tempus COVID-19 app';
const DOWNLOAD_TEXT = 'Download our mobile app for access to features on the go, such as view and share your results.';

const SIGNED_HIPAA_FORM_LINK = 'Signed HIPAA Authorization';

const UserContentContainer: FunctionComponent<UserContentContainer> = (props) => {
  const windowDimensions = useWindowDimensions();

  const isMobile = isMobileDevice(windowDimensions);

  return (
    <div className={css(isMobile ? styles.containerMobile : styles.container)}>
      <p className={css(textStyles.sectionHeader)}>{props.header}</p>
      <p className={css(textStyles.bodyText)}>{props.text}</p>
      <props.links />
    </div>
  );
};

const UserContent: FunctionComponent = () => {
  const { push: navigate } = useHistory();
  const patientInfoState = usePatientInfo();
  const patientInfo = patientInfoState.patientInfo!;

  const formSubmission = getHipaaConsentForm(patientInfo.forms)?.form;

  if (
    (patientInfo.consent?.consented && patientInfo.consent.physicianContactInfoProvided) ||
    (!!formSubmission && formSubmission.pcpInstitution)
  ) {
    return (
      <UserContentContainer
        header={THANK_YOU_HEADER}
        text={THANK_YOU_TEXT}
        links={() => {
          return (
            <a
              className={css(textStyles.link, textStyles.bodyText, styles.link, styles.underline)}
              onClick={() => {
                navigate(NavigationPath.CONSENT_RECEIPT);
                return false;
              }}
            >
              {SIGNED_HIPAA_FORM_LINK}
            </a>
          );
        }}
      />
    );
  } else if (
    (!!formSubmission && !formSubmission.pcpInstitution) ||
    (patientInfo.consent?.consented &&
      !patientInfo.consent.physicianContactInfoProvided &&
      !patientInfo.consent.neverAskForPCPAgain)
  ) {
    return (
      <UserContentContainer
        header={CONTRIBUTE_HEADER}
        text={CONTRIBUTE_TEXT}
        links={() => {
          return (
            <Fragment>
              <a
                className={css(textStyles.link, textStyles.bodyText, styles.link)}
                onClick={() => navigate(NavigationPath.PCP)}
              >
                <div className={css(styles.pcpLinkContainer)}>
                  <Card>
                    <div className={css(styles.pcpLinkContainerContent)}>
                      <p className={css(textStyles.bodyText)}>{PCP_LINK}</p>
                      <ChevronRight />
                    </div>
                  </Card>
                </div>
              </a>
              <a
                className={css(textStyles.link, textStyles.bodyText, styles.link, styles.underline)}
                onClick={() => {
                  navigate(NavigationPath.CONSENT_RECEIPT);
                  return false;
                }}
              >
                {SIGNED_HIPAA_FORM_LINK}
              </a>
            </Fragment>
          );
        }}
      />
    );
  } else {
    return (
      <UserContentContainer
        header={DOWNLOAD_HEADER}
        text={DOWNLOAD_TEXT}
        links={() => {
          return (
            <div className={css(styles.imageContainer)}>
              <a href={appleStoreLink} target="_blank" rel="noreferrer">
                <img className={css(styles.appleImage)} alt={appleStoreAltText} src={AppStoreButton} />
              </a>
              <a href={googlePlayLink} target="_blank" rel="noreferrer">
                <img className={css(styles.googleImage)} alt={googlePlayAltText} src={GooglePlayButton} />
              </a>
              <div className={css(styles.imagePadding)} />
            </div>
          );
        }}
      />
    );
  }
};

export default UserContent;

const styles = StyleSheet.create({
  container: {
    margin: '2.14em',
  },
  containerMobile: {
    margin: '0 2.14em 2.14em 2.14em',
  },
  link: { color: 'black', textDecoration: 'none' },
  underline: { textDecoration: 'underline' },
  appleImage: {
    height: '1.79em',
    width: '5.35em',
    paddingRight: '1.43em',
  },
  googleImage: {
    height: '2.57em',
    width: '6.14em',
  },
  pcpLinkContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: '1.43em',
  },
  pcpLinkContainerContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    margin: '-1.5em 0', // TODO: fix padding on release of next update to Card component
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  imagePadding: {
    display: 'flex',
    flex: 1,
  },
});
