import { FinancialAssistanceStatuses, Relationship, BillTypes, BenefitsCheckStatus } from 'requisition-service-shared';
import { DropdownOption } from '@tcl-argon-models/models';
import { IconProps } from '@tcl-boron-icons/icons/dist/@tcl-boron-icons/Icon';
import { Fail, Information, Success } from '@tcl-boron-icons/icons';
import { BrandPalette, ErrorPalette, SuccessPalette } from '@tcl-boron-colors/colors';

export type SelfPayConfigType = {
  link?: string;
  amount?: number;
  approvalText: string[];
  hidePaymentInfo?: boolean;
  showFACard?: boolean;
  faCardStatusText?: string;
  faCardPaymentText?: string;
  faCardContactButtons?: boolean;
  icon?: React.FC<IconProps>;
  iconColor?: string;
};

export const DEFAULT_PAYMENT_TEXT = 'You can pay now or later.';

export const selfPayConfig: Record<string, SelfPayConfigType> = {
  [FinancialAssistanceStatuses.PatientRefused]: {
    link: 'https://tempus.jotform.com/203344570290147',
    amount: 295,
    approvalText: [],
  },
  [FinancialAssistanceStatuses.Denied]: {
    link: 'https://tempus.jotform.com/213225909569161',
    amount: 295,
    approvalText: [
      'Based on the information you submitted, you have been denied for financial assistance.',
      'Our team will perform a benefits check and reach out if you are expected to owe more than $295 under your insurance policy.',
    ],
    showFACard: true,
    faCardStatusText: 'Denied',
    faCardPaymentText: 'If you would like to select the $295 Self Pay route now, you can select Pay Now below.',
    icon: Fail,
    iconColor: ErrorPalette[700],
  },
  [FinancialAssistanceStatuses.ApprovedNoMoreThan250]: {
    link: 'https://tempus.jotform.com/241505374184859',
    amount: 250,
    approvalText: ['Congratulations! You have been approved for financial assistance.'],
    showFACard: true,
    faCardStatusText: 'Approved',
    icon: Success,
    iconColor: SuccessPalette[700],
  },
  [FinancialAssistanceStatuses.ApprovedNoMoreThan200]: {
    link: 'https://tempus.jotform.com/231356559269164',
    amount: 200,
    approvalText: ['Congratulations! You have been approved for financial assistance.'],
    showFACard: true,
    faCardStatusText: 'Approved',
    icon: Success,
    iconColor: SuccessPalette[700],
  },
  [FinancialAssistanceStatuses.ApprovedNoMoreThan100]: {
    link: 'https://tempus.jotform.com/231356630045045',
    amount: 100,
    approvalText: ['Congratulations! You have been approved for financial assistance.'],
    showFACard: true,
    faCardStatusText: 'Approved',
    icon: Success,
    iconColor: SuccessPalette[700],
  },
  [FinancialAssistanceStatuses.ApprovedForFullCoverage]: {
    amount: 0,
    approvalText: ['Congratulations! You have been approved for financial assistance.'],
    showFACard: true,
    faCardStatusText: 'Approved',
    icon: Success,
    iconColor: SuccessPalette[700],
  },
  [FinancialAssistanceStatuses.ReceivedPaperCopyNotYetProcessed]: {
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
  [FinancialAssistanceStatuses.Pending]: {
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
  [FinancialAssistanceStatuses.PaperReceived]: {
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
  [FinancialAssistanceStatuses.MissingIncome]: {
    amount: 295,
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
  [FinancialAssistanceStatuses.MissingHouseholdMembers]: {
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
  [FinancialAssistanceStatuses.MissingIncomeAndHouseholdMembers]: {
    approvalText: [
      'We are currently unable to determine the status of your application.',
      "Please contact our Customer Success team for further assistance. We're here to help!",
    ],
    hidePaymentInfo: true,
    showFACard: true,
    faCardStatusText: 'Pending',
    faCardContactButtons: true,
    icon: Information,
    iconColor: BrandPalette[700],
  },
};

export const relationshipOptions = [
  { label: 'Mother', value: Relationship.Mother },
  { label: 'Father', value: Relationship.Father },
  { label: 'Spouse', value: Relationship.Spouse },
  { label: 'Daughter', value: Relationship.Daughter },
  { label: 'Son', value: Relationship.Son },
  { label: 'Self', value: Relationship.Self },
  { label: 'Unknown', value: Relationship.Unknown },
];

export const paymentOptions: DropdownOption[] = [
  { label: 'Pay with credit/debit card', value: BillTypes.DomesticSelfPay },
  { label: 'Insurance', value: BillTypes.Insurance },
];

export const paymentConfirmationBCStatuses: BenefitsCheckStatus[] = [
  BenefitsCheckStatus.BC_OUTREACH_IN_PROGRESS,
  BenefitsCheckStatus.CS_OUTREACH_EXHAUSTED,
];
