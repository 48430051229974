import { OnboardingConsent } from '../screens/consent/ConsentToTest';
import { OnboardingFinancialAssistance } from '../screens/financial-assistance/FinancialAssistance';
import { OnboardingTermsOfUse } from '../screens/terms-of-use/TermsOfUse';
import { OnboardingReviewPatientInfo } from '../screens/patient-info/ReviewPatientInfo';
import { OnboardingPaymentDetails } from '../screens/payment-details/PaymentDetails';
import { OnboardingStep } from '@tempus/patient-web-service-shared';

type OnboardingStepConfig = {
  path: string;
  component: React.ComponentType<{
    next?: () => Promise<void>;
    prev?: () => Promise<void>;
    skip?: () => Promise<void>;
  }>;
  showPrevious?: boolean;
  isFinalStep?: boolean;
};

export const onboardingStepConfig: Record<OnboardingStep, OnboardingStepConfig> = {
  [OnboardingStep.TERMS_OF_USE]: {
    path: '/onboarding/terms-of-use',
    component: OnboardingTermsOfUse,
  },
  [OnboardingStep.CONSENT_TO_TEST]: {
    path: '/onboarding/consent-to-test',
    component: OnboardingConsent,
  },
  [OnboardingStep.PATIENT_INFO]: {
    path: '/onboarding/patient-info',
    component: OnboardingReviewPatientInfo,
  },
  [OnboardingStep.FINANCIAL_ASSISTANCE]: {
    path: '/onboarding/financial-assistance',
    component: OnboardingFinancialAssistance,
    showPrevious: true,
  },
  [OnboardingStep.PAYMENT_DETAILS]: {
    path: '/onboarding/payment-details',
    component: OnboardingPaymentDetails,
    isFinalStep: true,
  },
};
