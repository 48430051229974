import React, { FunctionComponent, createRef, useEffect, useState } from 'react';
import { PortalContainer } from '../../components/PortalContainer';
import Grid from '@mui/material/Grid';
import PDFViewer from './components/PDFViewer';
import { Modal, Typography } from '@mui/material';
import { Button } from '@tcl-boron-prefabs/button';
import Loader from '@tcl-boron-prefabs/loader';
import { ArrowLeft, Download as DownloadIcon } from '@tcl-boron-icons/icons';
import { IconButton } from '@tcl-boron-prefabs/icon-button';
import ErrorScreen from '../../components/ErrorScreen';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { useHistory } from 'react-router-dom';
import logger from '../../../../utils/logger';
import usePortalContext from '../../context/usePortalContext';
import triggerDownload from './triggerDownload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'white',
  borderRadius: '8px',
  padding: '32px',
};

type ReportState = Record<string, Blob>;

export const Report: FunctionComponent = () => {
  const { patient, fetchHelper } = usePortalContext();

  const { push } = useHistory();
  const [pdfs, setPdfs] = useState<ReportState>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState(patient.reports[0]?.id);
  const containerRef = createRef<HTMLDivElement>();

  const currentReport = selectedReport && pdfs?.[selectedReport];

  const getReport = async (reportId: string) => {
    try {
      const response = await fetchHelper<Blob>(`/report/${reportId}`);

      setPdfs((prev) => ({ ...prev, [reportId]: response }));
    } catch (e) {
      logger.error(e as Error, 'Error fetching report');
    }
  };

  const getReports = async () => {
    if (patient.reports) {
      await Promise.allSettled(patient.reports.map((report) => getReport(report.id)));
    }

    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, []);

  const onClickDownloadButton = () => {
    setShowModal(true);
  };

  const markReportAsDownloaded = async (reportId: string) => {
    try {
      await fetchHelper(`/report/${reportId}/download`, {
        method: 'POST',
      });
    } catch (e) {
      logger.error(e as Error, 'Error marking report as downloaded');
    }
  };

  const downloadReports = async () => {
    if (!patient.reports) {
      return;
    }

    for (const report of patient.reports) {
      const reportPdf = pdfs?.[report.id];
      if (reportPdf) {
        await markReportAsDownloaded(report.id);
        triggerDownload(reportPdf, `${report.label.replace(/\s/g, '')}Report.pdf`);
      }
    }

    setShowModal(false);
  };

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)} data-testid="download-report-modal">
        <Grid container direction="column" gap={2} paddingRight={'32px'} sx={style}>
          <Typography variant="h1">Confirm Download</Typography>
          <Typography variant="body1">
            Once you download this file, it will no longer be secured by Tempus. Other apps on your device might have
            access to this file.
          </Typography>
          <Typography variant="body2">Do you want to continue?</Typography>
          <Grid container justifyContent="space-between">
            <Button
              buttonType="tertiary"
              onClick={() => setShowModal(false)}
              ariaLabel="Cancel"
              data-testid="cancel-download-button"
            >
              <Typography variant="button">Cancel</Typography>
            </Button>
            <Button
              buttonType="primary"
              onClick={downloadReports}
              ariaLabel="Download"
              data-testid="confirm-download-button"
            >
              <Typography variant="button">Download</Typography>
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <PortalContainer
        OverrideMenuElement={<IconButton name="Back" ariaLabel="Back" Icon={ArrowLeft} onClick={() => push('/')} />}
        HeaderElement={
          loading ? undefined : (
            <IconButton
              data-testid="download-report-button"
              name="Download Report"
              ariaLabel="Download Report"
              Icon={DownloadIcon}
              onClick={onClickDownloadButton}
            />
          )
        }
      >
        {loading ? (
          <Grid item container flexGrow={1} direction="column" gap="8px" alignItems="center" justifyContent="center">
            <Loader size="large" />
            <Typography variant="body1">Loading reports</Typography>
          </Grid>
        ) : (
          <Grid item container flexGrow={1} direction="column" alignItems="center">
            {patient.reports && (
              <SingleSelectDropdown
                data-testid="report-selector"
                value={{
                  value: selectedReport,
                  label: patient.reports.find((report) => report.id === selectedReport)?.label ?? '',
                }}
                label="Select report"
                onChange={(e) => {
                  setSelectedReport(e?.value ?? '');
                }}
                options={patient.reports.map((report) => ({ value: report.id, label: report.label }))}
                style={{ width: '90%', maxWidth: '512px', margin: '16px' }}
                hideLabel
              />
            )}
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              ref={containerRef}
              flexGrow={1}
            >
              {currentReport ? <PDFViewer pdf={currentReport} containerRef={containerRef} /> : <ErrorScreen />}
            </Grid>
          </Grid>
        )}
      </PortalContainer>
    </>
  );
};

export default Report;
