import { Grid, Typography } from '@mui/material';
import ErrorIcon from '../../assets/images/ErrorIcon.svg';

const ErrorScreen = () => (
  <Grid container direction="column" gap="16px" alignItems="center" padding="16px">
    <img src={ErrorIcon} />
    <Typography variant="h1">Problem loading...</Typography>
    <Typography variant="body1">Something went wrong. Try refreshing the page or come back later.</Typography>
  </Grid>
);

export default ErrorScreen;
