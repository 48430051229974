import { Grid } from '@mui/material';
import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = window.pdfWorker;

const PDF_TYPE = 'application/pdf';
const IMAGE_TYPE = 'image/';

const AttachmentViewer: React.FC<{ doc?: string; docType?: string }> = ({ doc, docType }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [docLoaded, setDocLoaded] = useState<boolean>(false);

  if (!doc || !docType) {
    return null;
  }

  const docData = `data:${docType};base64,${doc.replace(/(\r\n|\n|\r)/gm, '')}`;

  const onDocumentLoadSuccess = () => {
    setDocLoaded(true);
  };

  return (
    <Grid item width="100%" ref={ref}>
      {docType === PDF_TYPE ? (
        <Document file={docData} onLoadSuccess={onDocumentLoadSuccess}>
          {docLoaded ? (
            <Page pageNumber={1} width={ref.current?.offsetWidth} height={ref.current?.offsetHeight} />
          ) : null}
        </Document>
      ) : docType.startsWith(IMAGE_TYPE) ? (
        <img src={docData} style={{ width: '100%' }} />
      ) : null}
    </Grid>
  );
};

export default AttachmentViewer;
