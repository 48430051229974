import React, { useState, useCallback, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useContent } from '../../api/Content';
import { RichTextLines } from '../../app/components/richtext/RichText';
import { grayPalette } from '@tcl-argon-colors/colors';
import { Locked } from '@tcl-argon-icons/icons';
import { styles, Breakpoints } from '../../constants/styles';
import ConsentHipaaForm from './ConsentHipaaForm';
import { ConsentFooter } from './commonComponents';
import ContentLayout from '../../app/components/screen/ContentLayout';
import PhysicianInfoForm from './PhysicianInfoForm';
import { formConstants, getHipaaConsentForm, usePatientInfo } from '../../api/PatientInfo';
import Loading from '../../app/components/screen/Loading';
import { useHistory } from 'react-router-dom';
import { NavigationPath } from '../../api/constants';
import { commonStyles } from '../../constants/commonStyles';
import { setConsentForm } from '../../api/PatientConsent';

enum ConsentPhase {
  CONSENT_OVERVIEW = 'CONSENT_OVERVIEW',
  CONSENT_HIPAA = 'CONSENT_HIPAA',
  PHYSICIAN_INFO_FORM = 'PHYSICIAN_INFO_FORM',
}

const ConsentScreen: FunctionComponent = () => {
  const { push: navigate } = useHistory();
  const { consentLanguage, consentHeader } = useContent() ?? {};
  const [phase, setPhase] = useState(ConsentPhase.CONSENT_OVERVIEW);
  const [success, setSuccess] = useState(false);
  const { patientInfo, refreshPatientInfo } = usePatientInfo();

  const formSubmission = getHipaaConsentForm(patientInfo?.forms);

  const navigateToHipaa = useCallback(() => setPhase(ConsentPhase.CONSENT_HIPAA), []);

  const skipConsent = () => {
    setConsentForm({
      consented: false,
      diseaseType: formConstants.DEFAULT_DISEASE_TYPE,
      formType: formConstants.DEFAULT_FORM_TYPE,
      formVersion: formConstants.DEFAULT_FORM_VERSION,
    });
    refreshPatientInfo();
    setSuccess(true);
  };

  const consentedHipaa = (consented: boolean) => {
    if (consented) {
      setPhase(ConsentPhase.PHYSICIAN_INFO_FORM);
    } else {
      refreshPatientInfo();
      setSuccess(true);
    }
  };

  if (success || patientInfo!.consent || formSubmission) {
    // we need to wait for all state transitions to complete before we navigate away, to prevent premature exit
    setImmediate(() => navigate(NavigationPath.ORDERS));
    return <Loading />;
  } else if (phase === ConsentPhase.CONSENT_HIPAA) {
    return <ConsentHipaaForm next={consentedHipaa} />;
  } else if (phase === ConsentPhase.PHYSICIAN_INFO_FORM) {
    return (
      <PhysicianInfoForm
        next={() => {
          refreshPatientInfo();
          setSuccess(true);
        }}
      />
    );
  }

  return (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer, ConsentStyles.consentContainer)}>
        <div className={css(ConsentStyles.headerText, ConsentStyles.textAlign, styles.title)}>Consent</div>
        <RichTextLines
          viewStyle={ConsentStyles.disclaimerContainer}
          style={ConsentStyles.centerContent}
          lineStyle={[ConsentStyles.disclaimer, styles.bodyText]}
          lines={consentHeader ?? []}
        />
        <div className={css(ConsentStyles.textAlign)}>
          <Locked width={20} height={20} fill={grayPalette.gray100} />
        </div>
        <RichTextLines
          style={ConsentStyles.centerContent}
          viewStyle={ConsentStyles.centerContent}
          lineStyle={[ConsentStyles.content, styles.bodyText]}
          lines={consentLanguage ?? []}
          doubleSpace={false}
          headerStyle={[ConsentStyles.contentHeader, styles.bodyHeader]}
        />
        <ConsentFooter
          accept={navigateToHipaa}
          decline={skipConsent}
          submitButtonText="Continue"
          supportingText="Would you rather go directly to your results page?"
          declineText="Skip for now"
        />
      </div>
    </ContentLayout>
  );
};

export default ConsentScreen;

const ConsentStyles = StyleSheet.create({
  consentContainer: {
    maxWidth: '24.29em',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      maxWidth: '21.43em',
    },
  },
  content: {
    color: grayPalette.gray100,
    marginBottom: '2.14em',
  },
  disclaimerContainer: {
    marginBottom: '2.14em',
  },
  contentHeader: {
    color: grayPalette.gray100,
    marginBottom: '0.71em',
    marginTop: '1.07em',
  },
  disclaimer: {
    color: grayPalette.gray100,
  },
  headerText: {
    marginBottom: '1.43em',
  },
  textAlign: {
    textAlign: 'center',
  },
  successTextContainer: { alignItems: 'center', margin: '0 2.14em', justifyContent: 'center' },
  centerContent: {
    textAlign: 'center',
  },
});
