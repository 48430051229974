import { StrictMode, useEffect } from 'react';
import { AppNavigationRoutes } from '../api/Navigation';
import { initAnalytics } from '../api/Pendo';
import AppNavigator from './components/navigation/AppNavigator';
import ErrorBoundary from './components/screen/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrandPalette } from '@tcl-boron-colors/colors';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      color: BrandPalette[700],
      fontFamily: 'IBM Plex Serif',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h2: {
      fontSize: '24px',
      fontFamily: 'IBM Plex Serif',
      color: BrandPalette[400],
    },
    subtitle1: {
      color: BrandPalette[700],
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
    },
    h3: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: '100%',
    },
  },
});

const App = (): JSX.Element => {
  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <StrictMode>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Router>
            <AppNavigator routes={AppNavigationRoutes} />
          </Router>
        </ThemeProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
