const triggerDownload = (blob: Blob, filename: string) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  const URL = window.URL || window.webkitURL;
  const fileUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = filename;
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  URL.revokeObjectURL(fileUrl);
};

export default triggerDownload;
