import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { ComponentProps } from 'react';

interface FormSingleSelectDropdownProps
  extends Omit<ComponentProps<typeof SingleSelectDropdown>, 'value' | 'onChange' | 'onBlur' | 'status' | 'subContent'> {
  name: string;
  validate?: FieldValidator<string>;
  onChangeSideEffects?: (value?: string) => void;
}

const FormSingleSelectDropdown: React.FC<FormSingleSelectDropdownProps> = ({ name, validate, onChangeSideEffects, ...dropdownProps }) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <SingleSelectDropdown
          {...dropdownProps}
          value={dropdownProps.options.find((option) => option.value === input.value)}
          onChange={(selectedItem) => {
            input.onChange(selectedItem?.value);
            if (onChangeSideEffects) {
              onChangeSideEffects(selectedItem?.value);
            }
          }}
          onBlur={input.onBlur}
          status={meta.error && meta.touched ? 'error' : 'default'}
          subContent={meta.error && meta.touched ? meta.error : null}
        />
      )}
    </Field>
  );
};

export default FormSingleSelectDropdown;
