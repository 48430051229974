import { Grid, Typography } from '@mui/material';
import { Edit } from '@tcl-argon-icons/icons';
import { AccentPalette } from '@tcl-boron-colors/colors';
import { css, StyleSheet } from 'aphrodite';
import { FC } from 'react';
import { Button } from '@tcl-boron-prefabs/button';
import usePortalContext from '../context/usePortalContext';

const EditButton: FC = () => {
  const { setIsFormEditOpen } = usePortalContext();
  const edit = () => {
    setIsFormEditOpen(true);
  };

  return (
    <Button buttonType="tertiary" onClick={edit} small={true} ariaLabel="Edit">
      <Grid className={css(EditButtonStyles.container)}>
        <Edit height={16} width={16} />
        <Typography variant="button">Edit</Typography>
      </Grid>
    </Button>
  );
};

export default EditButton;

const EditButtonStyles = StyleSheet.create({
  container: {
    color: AccentPalette[100],
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
