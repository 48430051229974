import { useContext } from 'react';
import PortalContext from './PortalContext';

const usePortalContext = () => {
  const context = useContext(PortalContext);

  if (!context) {
    throw new Error('usePortalContext must be used within a PortalProvider');
  }

  return context;
};

export default usePortalContext;
