import { NeuroReportTypes, ReportStatusesEnum, ReportTypes } from '@tempus/portal-service-shared';
import { PortalReport } from '../../../context/PortalContext';

const REPORTS_FOR_QNS: ReportTypes[] = [NeuroReportTypes.GENE_DRUG];

export const hasQnsReport = (reports: PortalReport[]) => {
  const hasQns = reports.filter((report) => REPORTS_FOR_QNS.includes(report.type)).some((report) => report.isQns);

  const hasCompleteNonQns = reports
    .filter((report) => REPORTS_FOR_QNS.includes(report.type))
    .some((report) => report.status === ReportStatusesEnum.COMPLETE && !report.isQns);

  return hasQns && !hasCompleteNonQns;
};
