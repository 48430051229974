import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Card } from '@tcl-boron-prefabs/card';
import { Button } from '@tcl-boron-prefabs/button';
import { Warning, Information } from '@tcl-boron-icons/icons';
import { GrayscalePalette, WarningPalette } from '@tcl-boron-colors/colors';
import { MissingOrderItems, RequiredItem } from '../types';
import { MISSING_ITEM_PATIENT_FIELDS, MISSING_ITEM_PROVIDER_FIELDS } from '../constants';
import { PATIENT_MOI_ALERT_CONFIG } from '../constants';
import usePortalContext from '../../../context/usePortalContext';
import isPathAllowed from '../../../shared/utils/isPathAllowed';

const PatientMissingItemsAlert: React.FC<{ missingOrderItems: RequiredItem[] }> = ({ missingOrderItems }) => {
  const { push } = useHistory();

  return (
    <Grid className={css(MOICardStyles.container)}>
      <Card className={css(MOICardStyles.patientMOICard)}>
        <div data-testid="patient-moi-card">
          <div className={css(MOICardStyles.headerContainer)}>
            <Warning color={WarningPalette[700]} />
            <Typography variant="h1">Missing items from your order</Typography>
          </div>
          <Typography variant="body1" className={css(MOICardStyles.subtext)}>
            You have the following items that are delaying your test results.
          </Typography>
          <div className={css(MOICardStyles.itemContainer)}>
            {missingOrderItems.map((item) => {
              const missingItemConfig = PATIENT_MOI_ALERT_CONFIG[item.type];
              if (!missingItemConfig) {
                return null;
              }
              return (
                <Button
                  key={item.type}
                  style={{ height: '32px' }}
                  ariaLabel={missingItemConfig.buttonText}
                  buttonType="secondary"
                  onClick={() => push(missingItemConfig.navigationPath)}
                >
                  {missingItemConfig.buttonText}
                </Button>
              );
            })}
          </div>
        </div>
      </Card>
    </Grid>
  );
};

const ProviderMissingItemsAlert: React.FC = () => {
  return (
    <Grid className={css(MOICardStyles.container)}>
      <Card className={css(MOICardStyles.providerMOICard)}>
        <div data-testid="provider-moi-card">
          <div className={css(MOICardStyles.headerContainer)}>
            <Information color={GrayscalePalette[700]} />
            <Typography variant="h1">Missing items from your clinic</Typography>
          </div>
          <Typography variant="body1" className={css(MOICardStyles.subtext)}>
            We have reached out to your clinician’s office to get the missing item(s).
          </Typography>
          <Typography variant="body1">No action is required from you at this time.</Typography>
        </div>
      </Card>
    </Grid>
  );
};

const MissingItemsAlert: React.FC<{ missingOrderItems: MissingOrderItems }> = ({ missingOrderItems }) => {
  const { patient } = usePortalContext();

  const patientMissingOrderItems = missingOrderItems.patient.filter(
    (item) =>
      MISSING_ITEM_PATIENT_FIELDS.includes(item.type) &&
      isPathAllowed(PATIENT_MOI_ALERT_CONFIG[item.type]?.navigationPath, patient.toggles),
  );

  const providerMissingOrderItems = missingOrderItems.provider.filter((item) =>
    MISSING_ITEM_PROVIDER_FIELDS.includes(item.type),
  );

  if (patientMissingOrderItems.length) {
    return <PatientMissingItemsAlert missingOrderItems={patientMissingOrderItems} />;
  } else if (providerMissingOrderItems.length) {
    return <ProviderMissingItemsAlert />;
  } else {
    return null;
  }
};

const MOICardStyles = StyleSheet.create({
  container: {
    marginBottom: '16px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    marginTop: '0px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',
  },
  patientMOICard: {
    backgroundColor: 'rgba(255, 197, 108, 0.4)',
    padding: '24px',
  },
  providerMOICard: {
    backgroundColor: 'rgba(214, 255, 252, 1)',
    padding: '24px',
  },
  subtext: { marginBottom: '16px', marginTop: '16px' },
});

export default MissingItemsAlert;
