import 'reflect-metadata';
import { Grid, Modal, Typography } from '@mui/material';
import ScreenHeader from '../../../components/ScreenHeader';
import { Button } from '@tcl-boron-prefabs/button';
import { relationshipOptions } from '../constants';
import { Form } from 'react-final-form';
import FormTextInput from '../../../shared/form/FormTextInput';
import { required } from '../../../shared/form/validators';
import FormSingleDatePicker from '../../../shared/form/FormSingleDatePicker';
import FormSingleSelectDropdown from '../../../shared/form/FormSingleSelectDropdown';
import usePortalContext from '../../../context/usePortalContext';
import { BillTypes } from 'requisition-service-shared';
import { BillingDoc, InsuranceDetails } from '../types';
import { useState } from 'react';
import UploadZone from './UploadZone';
import getBase64File from '../utils/getBase64File';
import LoadingIndicator from '../../../components/LoadingIndicator';
import logger from '../../../../../utils/logger';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'white',
  borderRadius: '8px',
  padding: '32px',
};

type InsuranceFormProps = {
  insuranceDetails?: InsuranceDetails;
  actionOverride?: () => Promise<void>;
  docs?: BillingDoc[];
  fetchDocs: () => Promise<void>;
};

export interface CachedFile extends File {
  content?: string;
}

const NUM_DOCS = 2;

export const InsuranceForm: React.FC<InsuranceFormProps> = ({ insuranceDetails, actionOverride, docs, fetchDocs }) => {
  const { fetchHelper, patient, setIsFormEditOpen } = usePortalContext();
  const [loading, setLoading] = useState(false);
  const [cachedFiles, setCachedFiles] = useState<(CachedFile | undefined)[]>([]);
  const [deleteFileIndex, setDeleteFileIndex] = useState<number | undefined>();
  const [deleting, setDeleting] = useState(false);

  const deleteAttachment = async (docId: string) => {
    try {
      await fetchHelper(`/billing-info/attachment/${docId}`, {
        method: 'DELETE',
      });
      await fetchDocs();
    } catch (e: any) {
      logger.error(e, 'Error deleting attachment');
    }
  };

  const deleteDocByIndex = async (docIndex: number) => {
    setDeleting(true);
    if (docs && docs[docIndex]) {
      await deleteAttachment(docs[docIndex].attachmentId);
    } else {
      setCachedFile(docIndex);
    }
    setDeleting(false);
  };

  const setCachedFile = async (index: number, file?: CachedFile) => {
    if (file) {
      file.content = (await getBase64File(file)) as string;
    }

    setCachedFiles((prev) => {
      const newFiles = [...prev];
      newFiles[index] = file;
      return newFiles;
    });
  };

  return (
    <>
      <Modal
        open={deleteFileIndex !== undefined}
        onClose={() => setDeleteFileIndex(undefined)}
        data-testid="delete-confirmation-dialog"
      >
        <Grid container direction="column" gap="20px" padding="20px" sx={style}>
          <Typography variant="h1">Are you sure you want to delete this file?</Typography>
          <Grid item container justifyContent="space-between" gap="20px">
            <Button
              buttonType="tertiary"
              ariaLabel="No"
              onClick={() => setDeleteFileIndex(undefined)}
              data-testid="delete-cancel-button"
            >
              <Typography variant="button">No</Typography>
            </Button>
            <Button
              buttonType="primary"
              ariaLabel="Yes"
              onClick={() => {
                if (deleteFileIndex !== undefined) {
                  deleteDocByIndex(deleteFileIndex);
                  setDeleteFileIndex(undefined);
                }
              }}
              data-testid="delete-confirm-button"
            >
              <Typography variant="button">Yes</Typography>
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Form
        initialValues={insuranceDetails}
        onSubmit={async (data) => {
          setLoading(true);

          try {
            await fetchHelper(`/billing-info/${patient.orderId}`, {
              method: 'PATCH',
              body: {
                billType: BillTypes.Insurance,
                primaryInsurance: data,
              },
            });
          } catch (e: any) {
            logger.error(e, 'Error syncing bill type');
          }

          for (let i = 0; i < cachedFiles.length; i++) {
            const cachedFile = cachedFiles[i];
            if (cachedFile) {
              try {
                await fetchHelper(`/billing-info/${patient.orderId}/attachment`, {
                  method: 'POST',
                  body: {
                    content: cachedFile.content,
                    contentType: cachedFile.type,
                    fileName: cachedFile.name,
                  },
                });
              } catch (e: any) {
                logger.error(e, 'Error uploading attachment');
              }
            }
          }

          if (actionOverride) {
            await actionOverride();
          }

          setIsFormEditOpen(false);
          setLoading(false);
        }}
        render={({ handleSubmit, invalid }) => {
          return (
            <Grid container direction="column" gap="20px" data-testid="insurance-form">
              <ScreenHeader text="Insurance Details" />
              <Typography variant="body1">
                Please review and edit the billing details submitted by your clinician.
              </Typography>
              <FormTextInput
                label="Primary Insurance*"
                placeholder="Insurance carrier name (BCBS, Aetna, etc.)"
                name="name"
                validate={required}
                data-testid="insurance-name"
              />
              <FormTextInput
                label="Policy Number*"
                placeholder="Policy number (e.g. XCF1234556)"
                name="policyNumber"
                validate={required}
                data-testid="policy-number"
              />
              <FormTextInput
                label="Group Number"
                placeholder="Group number (e.g. 1234567)"
                name="groupNumber"
                data-testid="group-number"
              />
              <FormSingleSelectDropdown
                label="Relationship to Policy Holder*"
                name="relationshipToInsured"
                options={relationshipOptions}
                validate={required}
                data-testid="relationship-to-policy-holder"
              />
              <FormTextInput
                label="Policy holder name*"
                placeholder="Doe, John"
                name="policyHolderName"
                validate={required}
                data-testid="policy-holder-name"
              />
              <FormSingleDatePicker
                label="Policy holder DOB*"
                name="policyHolderDob"
                validate={required}
                data-testid="policy-holder-dob"
              />
              <Typography variant="h3">Upload Insurance Card Images</Typography>
              <Typography variant="body1">
                Please upload a clear image of both the front and back of your insurance card.
              </Typography>
              <Typography variant="body1">Ensure the entire card is visible and the text is legible.</Typography>

              {docs ? (
                <Grid item container direction="row" rowGap="16px">
                  {Array.from({ length: NUM_DOCS }).map((_, i) => (
                    <Grid item container justifyContent="center" alignItems="center" xs={12 / NUM_DOCS} key={i}>
                      <UploadZone
                        docPreview={docs[i]?.content ?? cachedFiles[i]?.content}
                        docType={docs[i]?.contentType ?? cachedFiles[i]?.type}
                        setFile={async (file) => {
                          await setCachedFile(i, file);
                        }}
                      />
                    </Grid>
                  ))}
                  {Array.from({ length: NUM_DOCS }).map((_, i) => (
                    <Grid item container justifyContent="center" alignItems="center" xs={12 / NUM_DOCS} key={i}>
                      {cachedFiles[i] || docs[i] ? (
                        <Button
                          buttonType="tertiary"
                          small
                          onClick={() => setDeleteFileIndex(i)}
                          ariaLabel="Remove"
                          loading={deleting}
                          data-testid={`delete-doc-${i}-button`}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <LoadingIndicator />
              )}
              <Grid
                item
                container
                padding="30px"
                justifyContent="center"
                alignItems="center"
                position="sticky"
                bottom="0"
                bgcolor="#ffffff"
              >
                <Button
                  minWidth="150px"
                  ariaLabel={'Save and continue'}
                  onClick={handleSubmit}
                  disabled={invalid}
                  loading={loading}
                  data-testid="save-button"
                >
                  <Typography variant="button">Save and continue</Typography>
                </Button>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};
