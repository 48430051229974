import { Grid, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { PatientInfo } from './shared';
import PatientInfoCard from './PatientInfoCard';
import ReviewPatientInfoHeader from './ReviewPatientInfoHeader';
import { Button } from '@tcl-boron-prefabs/button';
import ErrorScreen from '../../components/ErrorScreen';
import { LoadingState, LoadingStatus } from '../../../utils/LoadingState';
import usePortalContext from '../../context/usePortalContext';
import withPortalContainer from '../../hooks/withPortalContainer';
import { useHistory } from 'react-router-dom';
import { NavigationPath } from '../../../api/Navigation';
import EditPatientInfoForm from './EditPatientInfoForm';

const ReviewPatientInfo: FC<{
  buttonTextOverride?: string;
  buttonTypeOverride?: 'primary' | 'secondary' | 'tertiary';
  actionOverride?: () => Promise<void>;
}> = ({ buttonTextOverride, buttonTypeOverride, actionOverride }) => {
  const [infoLoadingState, modifyInfoLoadingState] = useState<LoadingState<PatientInfo>>({
    status: LoadingStatus.PENDING,
  });
  const { isFormEditOpen, fetchHelper } = usePortalContext();
  const { push } = useHistory();

  const getPatientInfo = () => fetchAndSetState(`/patient-info`);

  // TODO: make function take a generic type
  const fetchAndSetState = (...args: Parameters<typeof fetchHelper>) =>
    fetchHelper<PatientInfo>(...args)
      .then((info) => modifyInfoLoadingState(() => ({ status: LoadingStatus.LOADED, result: info })))
      .catch((e) => {
        console.error(e);
        modifyInfoLoadingState(() => ({ status: LoadingStatus.FAILED, error: undefined }));
      });

  useEffect(() => {
    getPatientInfo();
  }, []);

  const meetsInfoRequirements = (infoState: LoadingState<PatientInfo>): boolean => {
    if (infoState.status !== 'loaded') {
      return false;
    }
    const { result: info } = infoState;

    const hasEmail = Boolean(info.email);
    const hasPhone = Boolean(info.phoneNumbers);

    return (
      info.address.street1 !== '' &&
      info.address.city !== '' &&
      info.address.state !== '' &&
      info.address.postalCode !== '' &&
      (hasEmail || hasPhone)
    );
  };

  const onContinue = async () => {
    push(NavigationPath.PORTAL);
  };

  return (
    <Grid container direction="column" flexGrow={1}>
      {infoLoadingState.status === LoadingStatus.FAILED ? (
        <ErrorScreen />
      ) : (
        <>
          <ReviewPatientInfoHeader />
          {isFormEditOpen && infoLoadingState.status === LoadingStatus.LOADED ? (
            <EditPatientInfoForm info={infoLoadingState.result} fetchAndSetState={fetchAndSetState} />
          ) : (
            <Grid container direction="column" flexGrow={1}>
              <PatientInfoCard infoLoadingState={infoLoadingState} />
              <Grid item container padding="30px 0px" justifyContent="center">
                <Button
                  buttonType={buttonTypeOverride ?? 'primary'}
                  minWidth="150px"
                  ariaLabel={buttonTextOverride ?? 'Continue'}
                  onClick={actionOverride ?? onContinue}
                  disabled={!meetsInfoRequirements(infoLoadingState)}
                >
                  <Typography variant="button">{buttonTextOverride ?? 'Continue'}</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default withPortalContainer(ReviewPatientInfo);

export const OnboardingReviewPatientInfo: React.FC<{ next?: () => Promise<void> }> = ({ next }) => (
  <ReviewPatientInfo actionOverride={next} />
);
