import { Card, Grid, Stack, Typography } from '@mui/material';
import { css, StyleSheet } from 'aphrodite';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FC } from 'react';
import EditButton from '../../components/EditButton';
import InfoItem from './InfoItem';
import { PatientInfo } from './shared';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Loaded, Pending, LoadingStatus } from '../../../utils/LoadingState';
import moment from 'moment';

interface PatientInfoCardProps {
  infoLoadingState: Pending | Loaded<PatientInfo>;
}

const haveFullAddress = (info: PatientInfo) => {
  return !!info.address.street1 && !!info.address.city && !!info.address.state && !!info.address.postalCode;
};

const PatientInfoCard: FC<PatientInfoCardProps> = ({ infoLoadingState: info }) => {
  const contents = (info: PatientInfo) => (
    <Grid className={css(PatientInfoCardStyles.contents)}>
      <Stack>
        <InfoItem itemName={'Name & Address'}>
          <Typography variant="body1">{`${info.name.first} ${info.name.last}`}</Typography>
          {haveFullAddress(info) ? (
            <>
              <Typography variant="body1">{info.address.street1}</Typography>
              {info.address.street2 && <Typography variant="body1">{info.address.street2}</Typography>}
              <Typography variant="body1">{`${info.address.city}, ${info.address.state} ${info.address.postalCode}`}</Typography>
            </>
          ) : (
            <Typography variant="body1">
              <i>No address submitted</i>
            </Typography>
          )}
        </InfoItem>
        <InfoItem itemName={'Date of Birth'}>
          <Typography variant="body1">{moment(info.dateOfBirth).format('MM/DD/YYYY')}</Typography>
        </InfoItem>
        <InfoItem itemName={'Phone'}>
          <Typography variant="body1">
            {!info.phoneNumbers || info.phoneNumbers.length === 0 ? (
              <i>Not submitted</i>
            ) : (
              parsePhoneNumber(info.phoneNumbers[0].number).formatInternational()
            )}
          </Typography>
        </InfoItem>
        <InfoItem itemName={'Email Address'}>
          {(info.email && <Typography variant="body1">{info.email}</Typography>) || (
            <Typography variant="body1">
              <i>Not submitted</i>
            </Typography>
          )}
        </InfoItem>
      </Stack>
      <EditButton />
    </Grid>
  );

  return (
    <Grid className={css(PatientInfoCardStyles.container)}>
      <Card className={css(PatientInfoCardStyles.card)}>
        {info.status === LoadingStatus.LOADED ? contents(info.result) : <LoadingIndicator size="large" />}
      </Card>
    </Grid>
  );
};

export default PatientInfoCard;

const PatientInfoCardStyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginTop: '32px',
    marginBottom: '32px',
  },
  card: {
    backgroundColor: 'white',
    marginBottom: '18px',
    padding: '18px',
  },
  contents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
