import React from 'react';
import { NavigationRoute, AppNavigationType } from '../../../api/Navigation';
import RouteWrapper from './RouteWrapper';
import { Switch, Route } from 'react-router-dom';
import { getFormRoutes } from '../../../screens/forms/forms';
import CustomToDoListRoot from '../../../screens/custom-to-do-list/components/CustomToDoListRoot';
import { featureEnabledInEnvironment } from '../../../../config/feature-toggles';
import { FullScreenError, FullScreenErrorType } from '../screen/FullScreenError';
import Auth0Wrapper from './Auth0Wrapper';

type RoutesWithContext = {
  routes: NavigationRoute[];
};

const Auth0RoutesWithContext = (props: RoutesWithContext) => {
  return (
    <Switch>
      {props.routes.map((route, index) => {
        return <RouteWrapper key={index} {...route} />;
      })}
    </Switch>
  );
};

const FormRoutes = () => {
  return (
    <Switch>
      {getFormRoutes().map((route, index) => (
        <Route key={index} {...route.routeProps} component={route.Component} />
      ))}
      <Route
        render={() => (
          <FullScreenError
            title={'Page Not Found'}
            message={'The page was deleted, the URL is incorrect, or some other error occurred.'}
            type={FullScreenErrorType.OUTAGE}
          />
        )}
      />
    </Switch>
  );
};

const CustomToDoList = () => {
  return <CustomToDoListRoot />;
};

type AppNavigatorProps = {
  routes: AppNavigationType;
};

const AppNavigator: React.FC<AppNavigatorProps> = (props) => {
  const { routes } = props;
  const auth0RoutePaths = routes.AUTH0_AUTHENTICATED.map((route) => route.path);

  return (
    <Switch>
      <Route path="/form" component={FormRoutes} />
      {featureEnabledInEnvironment('enableFormChecklist') ? (
        <Route path="/checklist" component={CustomToDoList} />
      ) : (
        <Route
          render={() => (
            <FullScreenError
              title={'Page Not Found'}
              message={'The page was deleted, the URL is incorrect, or some other error occurred.'}
              type={FullScreenErrorType.OUTAGE}
            />
          )}
        />
      )}
      {featureEnabledInEnvironment('enablePatientPortal') ? (
        <Route
          path={auth0RoutePaths}
          render={() => {
            return (
              <Auth0Wrapper>
                <Auth0RoutesWithContext routes={routes.AUTH0_AUTHENTICATED} />
              </Auth0Wrapper>
            );
          }}
        />
      ) : (
        <Route
          render={() => (
            <FullScreenError
              title={'Page Not Found'}
              message={'The page was deleted, the URL is incorrect, or some other error occurred.'}
              type={FullScreenErrorType.OUTAGE}
            />
          )}
        />
      )}
    </Switch>
  );
};

export default AppNavigator;
