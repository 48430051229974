import 'reflect-metadata';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import withPortalContainer from '../../hooks/withPortalContainer';
import { Button } from '@tcl-boron-prefabs/button';
import { Typography } from '@mui/material';
import ScreenHeader from '../../components/ScreenHeader';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { BenefitsCheckStatus, BillTypes, FinancialAssistanceStatuses } from 'requisition-service-shared';
import { SelfPayCard } from './components/SelfPayCard';
import { InsuranceCard } from './components/InsuranceCard';
import usePortalContext from '../../context/usePortalContext';
import { InsuranceForm } from './components/InsuranceForm';
import { paymentConfirmationBCStatuses, paymentOptions, selfPayConfig } from './constants';
import logger from '../../../../utils/logger';
import ErrorScreen from '../../components/ErrorScreen';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { BillingDoc, BillingInfo } from './types';
import { FinancialAssistanceCard } from './components/FinancialAssistanceCard';
import { Checkbox } from '@tcl-boron-prefabs/checkbox';
import PaymentConfirmationCard from './components/PaymentConfirmationCard';
import { MissingOrderItems, PortalRequiredItemStatus } from '../home/types';
import { RequiredItemTypeEnum } from '@tempus/portal-service-shared';
import PaidCard from './components/PaidCard';
import PaymentProcessingModal from './components/PaymentProcessingModal';

type ActionButton = React.FC<{ onClick: React.MouseEventHandler; loading: boolean }>;

const PaymentDetails: React.FC<{
  UneditedButton: ActionButton;
  EditedButton: ActionButton;
  actionOverride?: () => Promise<void>;
}> = ({ UneditedButton, EditedButton, actionOverride }) => {
  const { isFormEditOpen, fetchHelper, patient } = usePortalContext();
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [edited, setEdited] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [docs, setDocs] = useState<BillingDoc[] | undefined>();
  const [paymentNoticeOpen, setPaymentNoticeOpen] = useState(false);
  const [selfPayPaid, setSelfPayPaid] = useState(false);

  const paymentConfig = billingInfo?.financialAssistanceStatus
    ? selfPayConfig[billingInfo.financialAssistanceStatus as string]
    : null;

  const needPaymentConfirmation =
    billingInfo?.benefitsCheckStatus && paymentConfirmationBCStatuses.includes(billingInfo?.benefitsCheckStatus);

  const fetchBilling = async () => {
    try {
      const response = await fetchHelper<BillingInfo>(`/billing-info/${patient.orderId}`);
      setBillingInfo(response);
    } catch (e: any) {
      logger.error(e, 'Error fetching billing info');
      setError(true);
    }
  };

  const fetchDocs = async () => {
    try {
      setDocs(undefined);
      const response = await fetchHelper<BillingDoc[]>(`/billing-info/attachments`);
      setDocs(response);
    } catch (e: any) {
      logger.error(e, 'Error fetching billing docs');
    }
  };

  const fetchMissingOrderItems = async () => {
    try {
      const response = await fetchHelper<MissingOrderItems>(`/orders/${patient.orderId}/missing-items`);

      const paymentStatusItem = response.patient.find((item) => item.type === RequiredItemTypeEnum.PAYMENT_RECEIVED);
      setSelfPayPaid(paymentStatusItem?.status === PortalRequiredItemStatus.RECEIVED);
    } catch (e) {
      logger.error(e as Error, 'Error fetching missing items');
    }
  };

  const fetchData = async () => {
    await Promise.all([fetchBilling(), fetchMissingOrderItems()]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchDocs();
  }, [isFormEditOpen]);

  const syncBillType = async () => {
    if (!billingInfo?.billType) {
      return;
    }

    try {
      await fetchHelper(`/billing-info/${patient.orderId}`, {
        method: 'PATCH',
        body: {
          billType: billingInfo.billType,
        },
      });
    } catch (e: any) {
      logger.error(e, 'Error syncing bill type');
    }
  };

  const confirmPaymentMethod = async () => {
    if (!needPaymentConfirmation || !billingInfo?.billType) {
      return;
    }

    setActionLoading(true);

    try {
      const response = await fetchHelper<BillingInfo>(`/billing-info/${patient.orderId}`, {
        method: 'PATCH',
        body: {
          billType: billingInfo.billType,
          benefitsCheckStatus:
            billingInfo.billType === BillTypes.Insurance
              ? BenefitsCheckStatus.OK_WITH_COST
              : BenefitsCheckStatus.SWITCH_TO_SP,
        },
      });

      setEdited(false);
      setBillingInfo(response);
    } catch (e: any) {
      logger.error(e, 'Error syncing bill type');
    }

    setActionLoading(false);
  };

  const onContinue = async (sync?: boolean) => {
    setActionLoading(true);

    if (sync) {
      await syncBillType();
      setEdited(false);
    }

    if (actionOverride) {
      await actionOverride();
    }

    setActionLoading(false);
  };

  if (error) {
    return <ErrorScreen />;
  }

  if (loading || !billingInfo) {
    return <LoadingIndicator />;
  }

  if (isFormEditOpen) {
    return (
      <InsuranceForm
        insuranceDetails={billingInfo?.primaryInsurance}
        actionOverride={actionOverride}
        docs={docs}
        fetchDocs={fetchDocs}
      />
    );
  }

  return (
    <>
      <PaymentProcessingModal open={paymentNoticeOpen} onClose={() => setPaymentNoticeOpen(false)} />
      {needPaymentConfirmation && billingInfo.billType === BillTypes.Insurance ? <PaymentConfirmationCard /> : null}
      <Grid container direction="column" flexGrow={1}>
        <ScreenHeader text={'Payment Details'} />
        <Grid item container direction="column" flexGrow={1} gap="30px" flexWrap="nowrap">
          {paymentConfig?.showFACard ? (
            <>
              <FinancialAssistanceCard
                paymentConfig={paymentConfig}
                setPaymentNoticeOpen={setPaymentNoticeOpen}
                paid={selfPayPaid}
              />
              {paymentConfig.hidePaymentInfo ? null : (
                <>
                  <InsuranceCard insuranceDetails={billingInfo.primaryInsurance} docs={docs} />
                  <Checkbox
                    label="Uninsured"
                    isSelected={billingInfo.billType === BillTypes.DomesticSelfPay}
                    value="Uninsured"
                    onChange={() => {
                      setBillingInfo((prev) => ({
                        ...(prev ? prev : {}),
                        billType:
                          billingInfo.billType === BillTypes.Insurance
                            ? BillTypes.DomesticSelfPay
                            : BillTypes.Insurance,
                      }));
                      setEdited(true);
                    }}
                  />
                </>
              )}
            </>
          ) : paymentConfig?.hidePaymentInfo ? null : selfPayPaid ? (
            <PaidCard amount={paymentConfig?.amount ?? 295} />
          ) : (
            <>
              <Typography variant="body1">
                Choose your preferred payment option and enter the necessary details.
              </Typography>
              <SingleSelectDropdown
                label="How do you want to pay?"
                options={paymentOptions}
                value={paymentOptions.find((option) => option.value === billingInfo.billType)}
                onChange={(value) => {
                  setBillingInfo((prev) => ({
                    ...(prev ? prev : {}),
                    billType: value?.value as BillTypes,
                  }));
                  setEdited(true);
                }}
                data-testid="bill-type-dropdown"
              />
              {billingInfo.billType === BillTypes.DomesticSelfPay && (
                <SelfPayCard
                  setPaymentNoticeOpen={setPaymentNoticeOpen}
                  payNowLink={paymentConfig?.link ?? selfPayConfig[FinancialAssistanceStatuses.PatientRefused].link}
                />
              )}
              {billingInfo.billType === BillTypes.Insurance && (
                <InsuranceCard insuranceDetails={billingInfo.primaryInsurance} docs={docs} />
              )}
            </>
          )}
        </Grid>
        <Grid item container padding="30px 0px" justifyContent="center">
          {needPaymentConfirmation ? (
            <Button
              buttonType="primary"
              minWidth="150px"
              ariaLabel="Confirm payment method"
              onClick={confirmPaymentMethod}
              loading={actionLoading}
              data-testid="confirm-payment-button"
            >
              <Typography variant="button">Confirm payment method</Typography>
            </Button>
          ) : edited ? (
            <EditedButton onClick={() => onContinue(true)} loading={actionLoading} />
          ) : (
            <UneditedButton onClick={() => onContinue()} loading={actionLoading} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withPortalContainer((props) => {
  const { push } = useHistory();

  const UneditedButton: ActionButton = ({ onClick, loading }) => (
    <Button
      buttonType="tertiary"
      minWidth="150px"
      ariaLabel="Back"
      onClick={onClick}
      loading={loading}
      data-testid="back-button"
    >
      Back
    </Button>
  );
  const EditedButton: ActionButton = ({ onClick, loading }) => (
    <Button
      buttonType="primary"
      minWidth="150px"
      ariaLabel="Save and return"
      onClick={onClick}
      loading={loading}
      data-testid="save-button"
    >
      Save and return
    </Button>
  );
  return (
    <PaymentDetails
      UneditedButton={UneditedButton}
      EditedButton={EditedButton}
      actionOverride={async () => {
        push('/');
      }}
      {...props}
    />
  );
});

export const OnboardingPaymentDetails: React.FC<{ next?: () => Promise<void> }> = ({ next }) => {
  const UneditedButton: ActionButton = ({ onClick, loading }) => (
    <Button
      buttonType="primary"
      minWidth="150px"
      ariaLabel="Continue"
      onClick={onClick}
      loading={loading}
      data-testid="continue-button"
    >
      Continue
    </Button>
  );
  const EditedButton: ActionButton = ({ onClick, loading }) => (
    <Button
      buttonType="primary"
      minWidth="150px"
      ariaLabel="Save and continue"
      onClick={onClick}
      loading={loading}
      data-testid="save-button"
    >
      Save and continue
    </Button>
  );
  return <PaymentDetails actionOverride={next} UneditedButton={UneditedButton} EditedButton={EditedButton} />;
};
