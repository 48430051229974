import { ReactElement, useState } from 'react';
import { Menu as MenuIcon, LinkOut, Close, ArrowLeft } from '@tcl-boron-icons/icons';
import { IconButton } from '@tcl-boron-prefabs/icon-button';
import Grid from '@mui/material/Grid';
import { Link, useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import React from 'react';
import { SequentialPalette } from '@tcl-boron-colors/colors';
import { useMediaQuery, useTheme } from '@mui/material';
import { Menu } from '@tcl-boron-prefabs/menu';
import usePortalContext from '../context/usePortalContext';
import { NotificationContextProvider } from '../notifications/NotificationContext';
import { NPP_URL, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../shared/constants';

const useStyles = createUseStyles({
  primaryMenuItem: {
    lineHeight: '20px',
    fontSize: '14px',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: SequentialPalette['ext100'],
      color: SequentialPalette[200],
      borderColor: SequentialPalette['ext100'],
      fontWeight: 'bold',
    },
  },
  secondaryMenuItem: {
    lineHeight: '14px',
    fontSize: '12px',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: SequentialPalette['ext100'],
      color: SequentialPalette[200],
      borderColor: SequentialPalette['ext100'],
      fontWeight: 'bold',
    },
  },
  divider: {
    borderBottom: '1px solid #D8D8D8',
    width: '106px',
    marginLeft: '16px',
  },
});

export type PortalContainerProps = {
  hideMenu?: boolean;
  HeaderElement?: ReactElement;
  OverrideMenuElement?: ReactElement;
};

export const PortalContainer: React.FC<PortalContainerProps> = ({
  children,
  hideMenu,
  HeaderElement,
  OverrideMenuElement,
}) => {
  const { divider, primaryMenuItem, secondaryMenuItem } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { isFormEditOpen, setIsFormEditOpen, patient } = usePortalContext();

  const menuItems = [
    [
      {
        text: 'PGx Report Status',
        id: '/',
        openNewTab: false,
      },
      {
        text: 'Contact Information',
        id: '/review_patient_info',
        openNewTab: false,
      },
      ...(patient?.toggles?.hidePaymentDetails
        ? []
        : [
            {
              text: 'Payment Details',
              id: '/payment_details',
              openNewTab: false,
            },
          ]),
      {
        text: 'Return Options',
        id: '/kit_return',
        openNewTab: false,
      },
      // TODO: Add back in when PRO Assessments are ready
      // {
      //   text: 'PRO Assessments',
      //   id: '/pro',
      //   openNewTab: false,
      // },
    ],
    [
      {
        text: 'About Us',
        id: 'https://www.tempus.com/patients/neuro-psych/', // TODO: Move into environment config: https://tempuslabs.atlassian.net/browse/NP-2203
        Icon: LinkOut,
        openNewTab: true,
      },
      {
        text: 'Terms of Use',
        id: TERMS_OF_USE_URL,
        Icon: LinkOut,
        openNewTab: true,
      },
      {
        text: 'Privacy Policy',
        id: PRIVACY_POLICY_URL,
        Icon: LinkOut,
        openNewTab: true,
      },
      {
        text: 'Notice of Privacy Practices',
        id: NPP_URL,
        Icon: LinkOut,
        openNewTab: true,
      },
      {
        text: 'Log Out',
        id: '/logout',
        openNewTab: false,
      },
    ],
  ];
  const history = useHistory();
  const renderIcon = () => {
    if (isFormEditOpen) {
      return <IconButton name="Back" Icon={ArrowLeft} onClick={() => setIsFormEditOpen(false)} ariaLabel="backIcon" />;
    } else if (OverrideMenuElement) {
      return OverrideMenuElement;
    } else if (hideMenu) {
      return <></>;
    } else if (isOpen) {
      return <IconButton name="Close Menu" Icon={Close} onClick={() => setIsOpen(false)} ariaLabel="closeIcon" />;
    }

    return <IconButton name="Home Menu" Icon={MenuIcon} onClick={() => setIsOpen(true)} ariaLabel="menuIcon" />;
  };

  return (
    <Grid
      container
      style={{
        height: '100%',
        alignContent: 'start',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        position: 'relative',
      }}
    >
      <Grid container style={{ width: '100%', minHeight: '78px', boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)' }}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          flexDirection="row"
          padding={{ xs: '12px', sm: '16px', md: '20px', lg: '20px', xl: '20px' }}
        >
          <Grid container item xs={2} justifyContent="flex-start" alignItems="center">
            {isLargeScreen ? (
              <Menu
                classes={{
                  menuItemRowEnabled: primaryMenuItem,
                }}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                menuItems={menuItems}
                onChange={(menuItemId) => {
                  // If the menu item is a URL, open it in a new tab
                  if (menuItemId?.startsWith('https://')) {
                    window.open(menuItemId, '_blank');
                  } else {
                    history.push(menuItemId!);
                  }
                }}
              >
                {renderIcon()}
              </Menu>
            ) : (
              renderIcon()
            )}
          </Grid>
          <Grid item xs={8} justifyContent="center" display="flex">
            <img src="https://go.tempus.com/rs/833-QRC-346/images/Tempus-Logo.png" alt="Tempus Logo" height="16px" />
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end" alignItems="center">
            {HeaderElement}
          </Grid>
        </Grid>
      </Grid>
      {isOpen && !isLargeScreen ? (
        <Grid container flexDirection="column" border="1px" padding="8px 0px 8px 0px">
          {menuItems.map((menuItemGroup, index) => (
            <React.Fragment key={index}>
              {menuItemGroup.map((menuItem, subIndex) => (
                <Link
                  key={subIndex}
                  className={index === 0 ? primaryMenuItem : secondaryMenuItem}
                  to={menuItem.openNewTab ? '' : menuItem.id}
                  onClick={() => {
                    if (menuItem.openNewTab) {
                      window.open(menuItem.id, '_blank');
                    }
                    setIsOpen(false);
                  }}
                  target={menuItem.openNewTab ? '_blank' : '_self'}
                >
                  <Grid
                    item
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    padding="6px 16px 6px 16px"
                    gap="8px"
                  >
                    <div>
                      {menuItem.text} {menuItem.Icon ? <menuItem.Icon height="12px" /> : null}
                    </div>
                  </Grid>
                </Link>
              ))}
              {index !== menuItems.length - 1 && <div className={divider} />}
            </React.Fragment>
          ))}
        </Grid>
      ) : null}
      <Grid
        container
        direction="column"
        overflow="auto"
        flexWrap="nowrap"
        flexGrow={1}
        style={{
          paddingLeft: isLargeScreen ? '150px' : '25px',
          paddingRight: isLargeScreen ? '150px' : '25px',
          display: isOpen && !isLargeScreen ? 'none' : 'flex',
        }}
        position="relative"
      >
        <NotificationContextProvider>{children}</NotificationContextProvider>
      </Grid>
    </Grid>
  );
};
