import { useEffect } from 'react';
import { PortalContainer } from '../components/PortalContainer';
import usePortalContext from '../context/usePortalContext';
import { Route, Switch, useHistory } from 'react-router-dom';
import { onboardingStepConfig } from './onboardingStepConfig';
import OnboardingInfo from '../context/interfaces/onboarding-status';
import { IconButton } from '@tcl-boron-prefabs/icon-button';
import { ArrowLeft } from '@tcl-boron-icons/icons';
import { OnboardingStep, OnboardingStatus } from '@tempus/patient-web-service-shared';

const OnboardingManager: React.FC = () => {
  const { onboardingInfo, setOnboardingInfo, fetchHelper } = usePortalContext();
  const { push } = useHistory();

  useEffect(() => {
    if (
      !onboardingInfo?.step ||
      !onboardingStepConfig[onboardingInfo.step] ||
      onboardingInfo.status === OnboardingStatus.COMPLETE
    ) {
      push('/');
      return;
    }

    push(onboardingStepConfig[onboardingInfo.step].path);
  }, [onboardingInfo]);

  const getPreviousStep = (step: OnboardingStep): OnboardingStep | undefined => {
    const stepKeys = Object.keys(onboardingStepConfig) as OnboardingStep[];
    const currentIndex = stepKeys.indexOf(step);
    if (currentIndex <= 0) {
      return;
    }

    return stepKeys[currentIndex - 1];
  };

  const updateStep = async (step: OnboardingStep, status: OnboardingStatus) => {
    return fetchHelper<OnboardingInfo>('/onboarding/step', {
      method: 'PATCH',
      body: {
        step,
        status,
      },
    });
  };

  const next = async () => {
    if (!onboardingInfo?.step) {
      return;
    }

    const response = await updateStep(onboardingInfo.step, OnboardingStatus.COMPLETE);
    setOnboardingInfo(response);
  };

  const prev = async () => {
    if (!onboardingInfo?.step) {
      return;
    }

    const previousStep = getPreviousStep(onboardingInfo.step);
    if (!previousStep) {
      return;
    }

    const response = await updateStep(previousStep, OnboardingStatus.IN_PROGRESS);
    setOnboardingInfo(response);
  };

  const skip = async () => {
    if (!onboardingInfo?.step) {
      return;
    }

    const response = await updateStep(onboardingInfo.step, OnboardingStatus.SKIPPED);
    setOnboardingInfo(response);
  };

  const showPrevious = onboardingInfo?.step && onboardingStepConfig[onboardingInfo.step].showPrevious;

  return (
    <PortalContainer
      hideMenu
      OverrideMenuElement={
        showPrevious ? (
          <IconButton
            name="Back"
            ariaLabel="Back"
            Icon={ArrowLeft}
            onClick={prev}
            data-testid="onboarding-previous-button"
          />
        ) : undefined
      }
    >
      <Switch>
        {Object.entries(onboardingStepConfig).map(([stepId, stepConfig]) => {
          return (
            <Route
              key={stepId}
              path={stepConfig.path}
              render={() => {
                return <stepConfig.component next={next} prev={prev} skip={skip} />;
              }}
            />
          );
        })}
      </Switch>
    </PortalContainer>
  );
};

export default OnboardingManager;
