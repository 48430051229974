import { Grid, Typography } from '@mui/material';
import { Card } from '@tcl-boron-prefabs/card';
import AddInsuranceButton from './AddInsuranceButton';
import EditButton from '../../../components/EditButton';
import { BillingDoc, InsuranceDetails } from '../types';
import AttachmentViewer from './AttachmentViewer';
import LoadingIndicator from '../../../components/LoadingIndicator';

type InsuranceCardProps = {
  insuranceDetails?: InsuranceDetails;
  docs?: BillingDoc[];
};

export const InsuranceCard: React.FC<InsuranceCardProps> = ({ insuranceDetails, docs }) => {
  return (
    <Grid container direction="column" gap="30px">
      <Card style={{ height: 'auto', padding: '16px' }}>
        <Grid container direction="column">
          <Grid item container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Typography variant="h1">Insurance Details</Typography>
            {insuranceDetails?.name ? <EditButton /> : <AddInsuranceButton />}
          </Grid>
          {insuranceDetails?.name ? (
            <>
              <Typography variant="body1">Primary: {insuranceDetails.name}</Typography>
              <Typography variant="body1">
                Policy Number: {insuranceDetails?.policyNumber ?? <i>Not Submitted</i>}
              </Typography>
              <Typography variant="body1">
                Group Number: {insuranceDetails?.groupNumber ?? <i>Not Submitted</i>}
              </Typography>
              <Typography variant="body1">
                Relationship to Patient: {insuranceDetails?.relationshipToInsured ?? <i>Not Submitted</i>}
              </Typography>
              <Typography variant="body1">
                Policy Holder Name: {insuranceDetails?.policyHolderName ?? <i>Not Submitted</i>}
              </Typography>
              <Typography variant="body1">
                Policy Holder DOB: {insuranceDetails?.policyHolderDob ?? <i>Not Submitted</i>}
              </Typography>
              <Grid
                item
                container
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
                paddingY="8px"
                flexWrap="nowrap"
              >
                {docs ? (
                  <>
                    {docs[0] ? (
                      <Grid item xs={6}>
                        <AttachmentViewer doc={docs[0].content} docType={docs[0].contentType} />
                      </Grid>
                    ) : null}

                    {docs[1] ? (
                      <Grid item xs={6}>
                        <AttachmentViewer doc={docs[1].content} docType={docs[0].contentType} />
                      </Grid>
                    ) : null}
                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </Card>
      <Typography variant="body1">Please review and edit the billing details submitted by your clinician.</Typography>
    </Grid>
  );
};
