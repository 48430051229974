import React, { FunctionComponent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { PortalContainer } from '../../components/PortalContainer';
import { Typography } from '@mui/material';
import moment from 'moment';
import formatEta from './utils/formatEta';
import Loader from '@tcl-boron-prefabs/loader';
import { Eta } from '@tempus/order-status-service-shared';
import { AllReportStatuses } from '@tempus/portal-service-shared';
import { Button } from '@tcl-boron-prefabs/button';
import { useHistory } from 'react-router-dom';
import logger from '../../../../utils/logger';
import ErrorScreen from '../../components/ErrorScreen';
import { BrandPalette, GrayscalePalette, ProgressPalette } from '@tcl-boron-colors/colors';
import usePortalContext from '../../context/usePortalContext';
import { MissingOrderItems, TimelineItem } from './types';
import MissingItemsAlert from './components/MissingItemsAlert';
import { hasQnsReport } from './utils/hasQnsReport';
import NodeIcon from './components/NodeIcon';
import { COMPLETE_STATUSES } from './constants';

export const Home: FunctionComponent = () => {
  const {
    patient: { orderId, reports },
    fetchHelper,
  } = usePortalContext();
  const [timeline, setTimeline] = useState<TimelineItem[]>([]);
  const [eta, setEta] = useState<Eta>();
  const [reportStatus, setReportStatus] = useState<AllReportStatuses>();
  const [missingOrderItems, setMissingOrderItems] = useState<MissingOrderItems>({ patient: [], provider: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { push } = useHistory();

  const hasQns = hasQnsReport(reports);

  const fetchTimeline = async () => {
    try {
      const response = await fetchHelper<TimelineItem[]>(`/orders/${orderId}/timeline`);

      setTimeline(response);
    } catch (e) {
      logger.error(e as Error, 'Error fetching timeline');
      throw e;
    }
  };

  const fetchETA = async () => {
    try {
      const response = await fetchHelper<Eta[]>(`/orders/${orderId}/eta`);

      if (response.length > 0) {
        setEta(response[0]);
      }
    } catch (e) {
      logger.error(e as Error, 'Error fetching ETA');
      throw e;
    }
  };

  const fetchReportStatus = async () => {
    try {
      const response = await fetchHelper<AllReportStatuses>(`/orders/${orderId}/report-status`);

      setReportStatus(response);
    } catch (e) {
      logger.error(e as Error, 'Error fetching report status');
      throw e;
    }
  };

  const fetchMissingOrderItems = async () => {
    try {
      const response = await fetchHelper<MissingOrderItems>(`/orders/${orderId}/missing-items`);

      setMissingOrderItems(response);
    } catch (e) {
      logger.error(e as Error, 'Error fetching missing items');
      throw e;
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([fetchTimeline(), fetchETA(), fetchReportStatus(), fetchMissingOrderItems()]);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PortalContainer>
      <Grid
        container
        flexGrow={1}
        direction="column"
        justifyContent={{
          xs: 'start',
          md: 'center',
        }}
        alignItems={{
          xs: 'stretch',
          md: 'center',
        }}
        maxWidth={1280}
        margin="0 auto"
      >
        {loading ? (
          <Grid container flexGrow={1} direction="column" gap="8px" alignItems="center" justifyContent="center">
            <Loader size="large" />
            <Typography variant="body1">Loading</Typography>
          </Grid>
        ) : error ? (
          <Grid container alignItems="center" justifyContent="center" flexGrow={1}>
            <ErrorScreen />
          </Grid>
        ) : (
          <>
            <Grid
              container
              direction="column"
              padding="30px 0px"
              gap="12px"
              flexGrow={1}
              width={{
                xs: '100%',
                md: 'fit-content',
              }}
            >
              {reportStatus !== AllReportStatuses.COMPLETE && (
                <MissingItemsAlert missingOrderItems={missingOrderItems!} />
              )}
              <Typography variant="h2">PGx Report Status</Typography>
              <Grid container direction="row" gap="8px">
                <Typography fontWeight="700" variant="body1" fontSize="16px" color={BrandPalette[400]}>
                  ETA:
                </Typography>
                <Typography fontWeight="400" variant="body1" fontSize="16px" color={BrandPalette[400]}>
                  {formatEta(eta, reportStatus, hasQns)}
                </Typography>
              </Grid>
              <Grid container flexGrow={1} direction="column" paddingTop="16px">
                {timeline?.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    direction="row"
                    wrap="nowrap"
                    flexGrow={item.isTerminalNode ? 0 : 1}
                    padding="8px 0"
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      flexBasis="25%"
                      alignItems="start"
                      padding="0px 16px 0 0"
                      gap="8px"
                    >
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <Grid item textAlign="right" flexGrow={1}>
                          <Typography
                            fontWeight="400"
                            fontSize="10px"
                            color={GrayscalePalette[600]}
                            overflow="hidden"
                            lineHeight="24px"
                            whiteSpace={'nowrap'}
                          >
                            {COMPLETE_STATUSES.includes(item.status) ? moment(item.date).format('MMM D') : null}
                          </Typography>
                        </Grid>
                        <NodeIcon itemStatus={item.status} reportStatus={reportStatus} />
                      </Grid>
                      {!item.isTerminalNode ? (
                        <Grid
                          alignSelf="flex-end"
                          flexGrow={1}
                          marginRight="6px"
                          border={`1px ${
                            COMPLETE_STATUSES.includes(item.status)
                              ? `solid ${ProgressPalette[700]}`
                              : `dashed ${GrayscalePalette[400]}`
                          }`}
                        />
                      ) : null}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      gap="4px"
                      justifyContent="start"
                      alignItems="start"
                      alignContent="start"
                    >
                      <Typography
                        fontWeight="600"
                        fontSize="14px"
                        lineHeight="24px"
                        color={COMPLETE_STATUSES.includes(item.status) ? GrayscalePalette[700] : GrayscalePalette[500]}
                      >
                        {item.label.toUpperCase()}
                      </Typography>
                      <Typography
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="14px"
                        color={COMPLETE_STATUSES.includes(item.status) ? GrayscalePalette[700] : GrayscalePalette[500]}
                        whiteSpace="wrap"
                      >
                        {item.subLabel}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {reportStatus === AllReportStatuses.COMPLETE && (
                <Grid item container paddingTop="30px" justifyContent="center">
                  <Button buttonType="primary" onClick={() => push('/report')} minWidth="250px" ariaLabel="View Report">
                    View my report
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </PortalContainer>
  );
};

export default Home;
