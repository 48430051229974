import ResetPassword from '../screens/auth/ResetPassword/ResetPassword';
import CreatePassword from '../screens/auth/CreatePassword/CreatePassword';
import TermsOfUse from '../screens/disclaimers/termsOfUse/TermsOfUse';
import FAQ from '../screens/disclaimers/faq/FAQ';
import ConsentReceipt from '../screens/consent/ConsentReceipt';
import IdentityVerification from '../screens/identityVerification/IdentityVerificationWrapper';
import Account from '../screens/account/Account';
import ConsentScreen from '../screens/consent/ConsentScreen';
import PhysicianInfoFormWrapper from '../screens/consent/PhysicianInfoFormWrapper';
import { HeaderType } from '../app/components/header/headerType';
import Result from '../screens/result';
import StatusScreen from '../screens/orders/StatusScreen';
import Home from '../portal/screens/home/Home';
import PaymentDetails from '../portal/screens/payment-details/PaymentDetails';
import KitReturn from '../portal/screens/kit-return/KitReturn';
import TempusPro from '../portal/screens/tempus-pro/TempusPro';
import Logout from '../portal/screens/logout/Logout';
import TermsOfUseV2 from '../portal/screens/terms-of-use/TermsOfUse';
import Report from '../portal/screens/report/Report';
import ReviewPatientInfo from '../portal/screens/patient-info/ReviewPatientInfo';
import OnboardingManager from '../portal/onboarding/OnboardingManager';
import { NavigationPath, OktaAuthNavigationPath, AuthenticationState } from './constants';

export type NavigationRoute = {
  component: React.ComponentType;
  header?: HeaderType;
  path: NavigationPath | OktaAuthNavigationPath;
  authState: AuthenticationState;
};

export type AppNavigationType = {
  [key in AuthenticationState]: NavigationRoute[];
};

const AppNavigationRoutes: AppNavigationType = {
  [AuthenticationState.VERIFIED]: [
    {
      component: ConsentScreen,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.CONSENT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: ConsentReceipt,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.CONSENT_RECEIPT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: Account,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.ACCOUNT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: PhysicianInfoFormWrapper,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.PCP,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: StatusScreen,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.ORDERS,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: Result,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.RESULT,
      authState: AuthenticationState.VERIFIED,
    },
  ],
  [AuthenticationState.AUTHENTICATED]: [
    {
      component: IdentityVerification,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.VERIFY,
      authState: AuthenticationState.AUTHENTICATED,
    },
  ],
  [AuthenticationState.UNAUTHENTICATED]: [
    {
      component: ResetPassword,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.RESET_PASSWORD,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: CreatePassword,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.REGISTER,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: TermsOfUse,
      path: NavigationPath.TERMS,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: FAQ,
      path: NavigationPath.FAQ,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
  ],
  [AuthenticationState.AUTH0_AUTHENTICATED]: [
    {
      component: ReviewPatientInfo,
      path: NavigationPath.REVIEW_PATIENT_INFO,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: TermsOfUseV2,
      path: NavigationPath.TERMS_OF_USE,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: PaymentDetails,
      path: NavigationPath.PAYMENT_DETAILS,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: KitReturn,
      path: NavigationPath.KIT_RETURN,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: TempusPro,
      path: NavigationPath.TEMPUS_PRO,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: Report,
      path: NavigationPath.REPORT,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: Logout,
      path: NavigationPath.LOGOUT,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    {
      component: OnboardingManager,
      path: NavigationPath.ONBOARDING,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
    // NOTE: Home MUST remain the last in the array, it is the default path
    {
      component: Home,
      path: NavigationPath.PORTAL,
      authState: AuthenticationState.AUTH0_AUTHENTICATED,
    },
  ],
};

export { AppNavigationRoutes, AuthenticationState, NavigationPath, OktaAuthNavigationPath };
