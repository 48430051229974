import React from 'react';
import { Route } from 'react-router-dom';
import ScreenLayout from '../screen/ScreenLayout';
import { NavigationRoute } from '../../../api/Navigation';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export type RouteWrapperProps = NavigationRoute;

// TODO: Enfource Auth0 Guard, include login redirect
const Auth0Route: React.FC<RouteWrapperProps> = (props) => {
  return (
    <Route
      path={props.path as string}
      render={() => {
        return (
          <ScreenLayout header={props.header}>
            <props.component />
          </ScreenLayout>
        );
      }}
    />
  );
};

export default withAuthenticationRequired(Auth0Route);
