import { AddSimple } from '@tcl-boron-icons/icons';
import { useState } from 'react';
import AttachmentViewer from './AttachmentViewer';

import Dropzone from 'react-dropzone';
import { Grid } from '@mui/material';
import LoadingIndicator from '../../../components/LoadingIndicator';

type UploadZoneProps = {
  docPreview?: string;
  docType?: string;
  setFile: (file?: File) => Promise<void>;
};

const UploadZone: React.FC<UploadZoneProps> = ({ docPreview, docType, setFile }) => {
  const [fileLoading, setFileLoading] = useState(false);

  const uploadFile = async (file: File) => {
    setFileLoading(true);
    await setFile(file);
    setFileLoading(false);
  };

  return fileLoading ? (
    <LoadingIndicator size="small" />
  ) : docPreview && docType ? (
    <Grid item container direction="column" gap="16px" justifyContent="center" alignItems="center" width="128px">
      <AttachmentViewer doc={docPreview} docType={docType} />
    </Grid>
  ) : (
    <Dropzone
      onDrop={(file) => uploadFile(file[0])}
      maxFiles={1}
      accept={{
        'image/*': ['.png', '.jpg', '.jpeg'],
        'application/pdf': ['.pdf'],
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Grid
          item
          container
          width="128px"
          padding="30px"
          justifyContent="center"
          alignItems="center"
          border="1px dashed black"
          data-testid="upload-zone"
          {...getRootProps()}
        >
          <input {...getInputProps()} style={{ display: 'none' }} data-testid="upload-zone-input" />
          <AddSimple data-testid="upload-zone-icon" />
        </Grid>
      )}
    </Dropzone>
  );
};

export default UploadZone;
