import 'reflect-metadata';
import { Grid, Typography } from '@mui/material';
import { Button } from '@tcl-boron-prefabs/button';
import { Form } from 'react-final-form';
import FormTextInput from '../../../shared/form/FormTextInput';
import { required } from '../../../shared/form/validators';
import FormSignature from '../../../shared/form/FormSignature';
import FormSingleDatePicker from '../../../shared/form/FormSingleDatePicker';
import FormSingleSelectDropdown from '../../../shared/form/FormSingleSelectDropdown';
import usePortalContext from '../../../context/usePortalContext';
import { useEffect, useState } from 'react';
import { NEURO_CONSENT_TO_TEST_KEY } from '@tempus/patient-forms-service-shared';
import { PatientInfo } from '../../patient-info/shared';
import Condition from '../../../shared/form/ConditionalField';
import logger from '../../../../../utils/logger';
import { NotificationType, useNotificationContext } from '../../../notifications/NotificationContext';

enum OPTION {
  YES = 'YES',
  NO = 'NO',
}

const IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS = [
  {
    label: 'I am the patient',
    value: OPTION.NO,
  },
  {
    label: 'I am a Legally Authorized Representative',
    value: OPTION.YES,
  },
];

export const ConsentForm: React.FC<{ actionOverride?: () => Promise<void> }> = ({ actionOverride }) => {
  const { fetchHelper, patient, setIsFormEditOpen } = usePortalContext();
  const [loading, setLoading] = useState(true);
  const [patientInfo, setPatientInfo] = useState<PatientInfo | undefined>();
  const { notify } = useNotificationContext();

  const getPatientInfo = async () => {
    setLoading(true);
    fetchHelper<PatientInfo>(`/patient-info`)
      .then((info) => {
        setPatientInfo(() => info);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        logger.error(e, 'Error fetching patient info');
      });
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  return (
    <Form
      onSubmit={async (data) => {
        setLoading(true);
        try {
          await fetchHelper(`/form/submit/${patient.orderId}`, {
            method: 'POST',
            body: {
              formType: NEURO_CONSENT_TO_TEST_KEY,
              orderId: patient.orderId,
              patientId: patient.patientId,
              data: {
                patientFirstName: data.name.first,
                patientLastName: data.name.last,
                patientDateOfBirth: data.dateOfBirth,
                isLegallyAuthorizedRepresentative: data.isLegallyAuthorizedRepresentative,
                signature: data.patientSignature,
                legallyAuthorizedRepresentativeFirstName: data.representativeFirstName,
                legallyAuthorizedRepresentativeLastName: data.representativeLastName,
                legallyAuthorizedRepresentativeRelationshipToPatient: data.relationship,
                representativeSignature: data.representativeSignature,
              },
            },
          });

          if (actionOverride) {
            await actionOverride();
          }
        } catch (e: any) {
          logger.error(e, 'Error submitting consent form');
          notify(NotificationType.ERROR, 'Error submitting Consent to Test, please try again');
        }

        setIsFormEditOpen(false);
        setLoading(false);
      }}
      initialValues={patientInfo}
      render={({ handleSubmit, invalid }) => {
        return (
          <Grid container direction="column" gap="20px">
            <Typography variant="subtitle1">
              If the patient name or date of birth is incorrect, email us at help@tempus.com.
            </Typography>
            <FormSingleSelectDropdown
              label="Select one of the following*"
              name="isLegallyAuthorizedRepresentative"
              options={IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS}
              validate={required}
              data-testid="is-legally-authorized-representative"
            />
            {!loading && (
              <>
                <FormTextInput
                  label="Patient First Name*"
                  placeholder="Patient first name"
                  name="name.first"
                  data-testid="patient-first-name"
                  disabled
                />
                <FormTextInput
                  label="Patient Last Name*"
                  placeholder="Patient last name"
                  name="name.last"
                  data-testid="patient-last-name"
                  disabled
                />
                <FormSingleDatePicker label="Patient DOB*" name="dateOfBirth" data-testid="patient-dob" disabled />
                <Condition when="isLegallyAuthorizedRepresentative" is={OPTION.YES}>
                  <FormTextInput
                    label="Legally Authorized Representative First Name*"
                    placeholder="Representative first name"
                    name="representativeFirstName"
                    validate={required}
                    data-testid="representative-first-name"
                  />
                  <FormTextInput
                    label="Legally Authorized Representative Last Name*"
                    placeholder="Representative last name"
                    name="representativeLastName"
                    validate={required}
                    data-testid="representative-last-name"
                  />
                  <FormTextInput
                    label="Relationship to Patient*"
                    placeholder="Signee Relationship"
                    name="relationship"
                    validate={required}
                    data-testid="signee-relationship"
                  />
                  <FormSignature
                    label="I represent that I am the Legally Authorized Representative and am authorized under state and federal law to order this test on behalf of the patient and receive the relevant results. I agree to the terms provided above.*"
                    name={'representativeSignature'}
                    validate={required}
                    data-testid="representative-signature-with-date"
                  />
                </Condition>
                <Condition when="isLegallyAuthorizedRepresentative" is={OPTION.NO}>
                  <FormSignature
                    label="I agree to the terms provided above.*"
                    name={'patientSignature'}
                    validate={required}
                    data-testid="patient-signature-with-date"
                  />
                </Condition>
              </>
            )}
            <Grid
              item
              container
              padding="30px"
              justifyContent="center"
              alignItems="center"
              position="sticky"
              bottom="0"
              bgcolor="#ffffff"
            >
              <Button
                minWidth="150px"
                ariaLabel={'Submit'}
                onClick={handleSubmit}
                disabled={invalid}
                loading={loading}
                data-testid="save-button"
              >
                <Typography variant="button">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
