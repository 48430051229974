import React, { useRef, useEffect, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { TextInput } from '@tcl-argon-prefabs/text-input';
import { Success as SuccessCircle } from '@tcl-argon-icons/icons';
import SignatureCanvas from 'react-signature-canvas';
import { RichTextLines } from '../../app/components/richtext/RichText';
import { styles, fonts } from '../../constants/styles';
import { formConstants, FormSubmission, getHipaaConsentForm, usePatientInfo } from '../../api/PatientInfo';
import { useOrders } from '../../api/Orders';
import ContentLayout from '../../app/components/screen/ContentLayout';
import { NavigationPath } from '../../api/constants';
import { commonStyles } from '../../constants/commonStyles';
import { getContent } from '../../../content';
import {
  FIRST_NAME_TEST_ID,
  MIDDLE_NAME_TEST_ID,
  LAST_NAME_TEST_ID,
  DATE_OF_BIRTH_TEST_ID,
  EMAIL_TEST_ID,
  PHONE_NUMBER_TEST_ID,
  ADDRESS_ONE_TEST_ID,
  ADDRESS_TWO_TEST_ID,
  CITY_TEST_ID,
  STATE_TEST_ID,
  GENDER_TEST_ID,
  ADDRESS_ONE_LABEL,
  ADDRESS_TWO_LABEL,
  CITY_LABEL,
  DATE_OF_BIRTH_LABEL,
  EMAIL_LABEL,
  FIRST_NAME_LABEL,
  GENDER_LABEL,
  LAST_NAME_LABEL,
  MIDDLE_NAME_LABEL,
  PHONE_NUMBER_LABEL,
  STATE_LABEL,
  TODAY_DATE_LABEL,
  TODAYS_DATE_TEST_ID,
} from './ConsentHipaaForm';

const ConsentReceiptContent: FunctionComponent = () => {
  const { push: navigate } = useHistory();
  const signatureRef = useRef<any>();
  const content = getContent(formConstants.DEFAULT_FORM_VERSION);
  const { hipaaAuthorization } = content;
  const { patientInfo } = usePatientInfo();
  const orders = useOrders();
  const { dateOfBirth, consent } = patientInfo ?? {};
  const fullName = orders?.length ? orders[0].fullName : '';

  // We should never display the receipt if data is missing or out of date
  const errors = [
    { hasError: !consent?.consented, message: 'consented is false' },
    { hasError: !consent?.updatedDate, message: 'missing updatedDate' },
    { hasError: !consent?.createdDate, message: 'missing createdDate' },
    { hasError: !consent?.signature, message: 'missing signature' },
    { hasError: !consent?.fullName, message: 'missing fullName' },
    {
      hasError: consent?.contentVersion !== consent?.content.version,
      message: `contentVersion mismatch (consent.contentVersion: ${consent?.contentVersion} !== consent.content.version: ${consent?.content.version})`,
    },
  ].filter(({ hasError }) => hasError);
  if (!consent || errors.length) {
    // TODO: uncomment for detailed error messages once airbrake is setup

    // const errorMessage = errors.map(({ message }) => message).join(', ');
    // throw new Error(`Error rendering ConsentReceiptContent: ${errorMessage}`);
    navigate(NavigationPath.ACCOUNT);
  }

  useEffect(() => {
    function resizeCanvas() {
      if (!signatureRef.current) {
        return;
      }
      const canvas = signatureRef.current.getCanvas();
      const signaturePad = signatureRef.current.getSignaturePad();
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.width * 0.4;
      canvas.getContext('2d').scale(ratio, ratio);
      signaturePad.clear();
      signaturePad.fromDataURL(consent?.signature);
    }
    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  useEffect(() => {
    if (signatureRef.current && consent?.signature) {
      signatureRef.current.fromDataURL(consent?.signature);
      signatureRef.current.off();
    }
  }, [consent?.signature]);

  return (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer)}>
        <div className={css(commonStyles.contentContainer, ConsentReceiptStyles.hipaaContainer)}>
          <div className={css([ConsentReceiptStyles.headerText, styles.title])}>HIPAA Authorization</div>
          <div className={css(ConsentReceiptStyles.signedTextContainer)}>
            <SuccessCircle color={grayPalette.gray60} height={15} width={15} />
            <div className={css(styles.bodyText, ConsentReceiptStyles.signedText)}>
              Signed: {moment(consent?.createdDate).format('MMM D, YYYY')}
            </div>
          </div>
          <RichTextLines
            style={ConsentReceiptStyles.hipaaContentContainer}
            lines={hipaaAuthorization ?? []}
            lineStyle={[ConsentReceiptStyles.hipaaContent, styles.bodyText]}
          />
          <div className={css(ConsentReceiptStyles.signatureWrapper)}>
            <label className={css(styles.supportingBodyText, fonts.ibmPlexSansMedium, ConsentReceiptStyles.labelText)}>
              Signature*
            </label>
            <SignatureCanvas
              ref={signatureRef}
              penColor={grayPalette.gray100}
              canvasProps={{
                className: css(ConsentReceiptStyles.signatureCanvas),
              }}
            />
          </div>
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentReceiptStyles.textInputWrapper,
                consent?.signature ? ConsentReceiptStyles.textInputDisabled : undefined,
              ),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            label="Name*"
            data-testid="name"
            required
            onChange={() => null}
            value={fullName}
            disabled={!!consent?.signature}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            label="Today's date*"
            data-testid="date"
            onChange={() => null}
            value={moment(consent?.createdDate).format('MMM D, YYYY')}
            disabled
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            label="Date of Birth*"
            data-testid="dob"
            onChange={() => null}
            value={moment(dateOfBirth).format('MMM D, YYYY')}
            disabled
          />
          {consent?.email && (
            <TextInput
              classes={{
                root: css(ConsentReceiptStyles.textInputContainer),
                label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
                inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
                input: css(ConsentReceiptStyles.inputStyle),
              }}
              label="Email"
              data-testid="email"
              onChange={() => null}
              value={consent.email}
              disabled
            />
          )}
        </div>
      </div>
    </ContentLayout>
  );
};

function ConsentFormContent({ form: formSubmission }: { form: FormSubmission }) {
  const signatureRef = useRef<any>();
  const content = getContent(formSubmission.formVersion);
  const form = formSubmission.form!;

  useEffect(() => {
    function resizeCanvas() {
      if (!signatureRef.current) {
        return;
      }
      const canvas = signatureRef.current.getCanvas();
      const signaturePad = signatureRef.current.getSignaturePad();
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.width * 0.4;
      canvas.getContext('2d').scale(ratio, ratio);
      signaturePad.clear();
      signaturePad.fromDataURL(form.signature);
    }
    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  useEffect(() => {
    if (signatureRef.current && form.signature) {
      signatureRef.current.fromDataURL(form.signature);
      signatureRef.current.off();
    }
  }, [form.signature]);

  return (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer)}>
        <div className={css(commonStyles.contentContainer, ConsentReceiptStyles.hipaaContainer)}>
          <div className={css([ConsentReceiptStyles.headerText, styles.title])}>HIPAA Authorization</div>
          <div className={css(ConsentReceiptStyles.signedTextContainer)}>
            <SuccessCircle color={grayPalette.gray60} height={15} width={15} />
            <div className={css(styles.bodyText, ConsentReceiptStyles.signedText)}>
              Signed: {moment(formSubmission.dateSubmitted).format('MMM D, YYYY')}
            </div>
          </div>
          <RichTextLines
            style={ConsentReceiptStyles.hipaaContentContainer}
            lines={content.hipaaAuthorization}
            lineStyle={[ConsentReceiptStyles.hipaaContent, styles.bodyText]}
          />
          <div className={css(ConsentReceiptStyles.signatureWrapper)}>
            <label className={css(styles.supportingBodyText, fonts.ibmPlexSansMedium, ConsentReceiptStyles.labelText)}>
              Signature*
            </label>
            <SignatureCanvas
              ref={signatureRef}
              penColor={grayPalette.gray100}
              canvasProps={{
                className: css(ConsentReceiptStyles.signatureCanvas),
              }}
            />
          </div>
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            required
            disabled={true}
            label={TODAY_DATE_LABEL}
            data-testid={TODAYS_DATE_TEST_ID}
            onChange={() => null}
            value={form.dateSubmitted}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={FIRST_NAME_LABEL}
            data-testid={FIRST_NAME_TEST_ID}
            required
            onChange={() => null}
            value={form.firstName}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={MIDDLE_NAME_LABEL}
            data-testid={MIDDLE_NAME_TEST_ID}
            onChange={() => null}
            value={form.middleName ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={LAST_NAME_LABEL}
            data-testid={LAST_NAME_TEST_ID}
            onChange={() => null}
            value={form.lastName}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={DATE_OF_BIRTH_LABEL}
            data-testid={DATE_OF_BIRTH_TEST_ID}
            onChange={() => null}
            value={form.dateOfBirth}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={GENDER_LABEL}
            data-testid={GENDER_TEST_ID}
            onChange={() => null}
            value={form.gender}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={EMAIL_LABEL}
            data-testid={EMAIL_TEST_ID}
            onChange={() => null}
            value={form.email}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={PHONE_NUMBER_LABEL}
            data-testid={PHONE_NUMBER_TEST_ID}
            onChange={() => null}
            value={form.phoneNumbers[0] ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={ADDRESS_ONE_LABEL}
            data-testid={ADDRESS_ONE_TEST_ID}
            onChange={() => null}
            value={form.street1}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={ADDRESS_TWO_LABEL}
            data-testid={ADDRESS_TWO_TEST_ID}
            onChange={() => null}
            value={form.street2 ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={CITY_LABEL}
            data-testid={CITY_TEST_ID}
            onChange={() => null}
            value={form.city}
          />
          <TextInput
            classes={{
              root: css(ConsentReceiptStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentReceiptStyles.textInputWrapper, ConsentReceiptStyles.textInputDisabled),
              input: css(ConsentReceiptStyles.inputStyle),
            }}
            disabled
            label={STATE_LABEL}
            data-testid={STATE_TEST_ID}
            onChange={() => null}
            value={form.state}
          />
        </div>
      </div>
    </ContentLayout>
  );
}

export default function ConsentReceipt() {
  const { patientInfo } = usePatientInfo();

  const formSubmission = getHipaaConsentForm(patientInfo!.forms);

  return formSubmission?.form ? <ConsentFormContent form={formSubmission} /> : <ConsentReceiptContent />;
}

const ConsentReceiptStyles = StyleSheet.create({
  inputStyle: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    color: grayPalette.gray100,
  },
  signedTextContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    alignSelf: 'flex-start',
  },
  signedText: {
    color: grayPalette.gray60,
    marginLeft: '10px',
  },
  headerText: {
    marginBottom: '1.42em',
  },
  hipaaContentContainer: {
    marginBottom: '0.71em',
  },
  hipaaContainer: {
    maxWidth: '27.14em',
  },
  hipaaContent: { color: grayPalette.gray100, marginBottom: '1.42em' },
  signatureWrapper: {
    marginBottom: '1.42em',
  },
  signatureCanvas: {
    width: '100%',
    borderRadius: '0.21em',
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
    border: `0.07em solid ${grayPalette.gray30}`,
    marginTop: '0.71em',
  },
  textInputContainer: {
    width: '100%',
    marginBottom: '1.42em',
  },
  textInputDisabled: {
    backgroundColor: grayPalette.gray10,
    opacity: 'unset',
  },
  textInputWrapper: {
    border: `0.07em solid ${grayPalette.gray30}`,
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
  },
  labelText: {
    marginBottom: '1.42em',
  },
});
