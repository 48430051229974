import { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Box, Typography } from '@mui/material';

const ScreenHeadingTextStyles = StyleSheet.create({
  container: {
    padding: '30px 0px 10px 0px',
  },
});

const ScreenHeader: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <Box className={css(ScreenHeadingTextStyles.container)}>
      <Typography variant="h1">{text}</Typography>
    </Box>
  );
};

export default ScreenHeader;
