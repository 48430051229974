import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import withPortalContainer from '../../hooks/withPortalContainer';
import ScreenHeader from '../../components/ScreenHeader';
import { Button } from '@tcl-boron-prefabs/button';
import { Typography } from '@mui/material';
import {
  NEURO_FINANCIAL_ASSISTANCE_FORM_KEY,
  NeuroFinancialAssistanceContent,
  getNeuroNumberFamilyMembersDropdownOptions,
} from '@tempus/patient-forms-service-shared';
import { Form } from 'react-final-form';
import FormTextInput from '../../shared/form/FormTextInput';
import FormSingleSelectDropdown from '../../shared/form/FormSingleSelectDropdown';
import usePortalContext from '../../context/usePortalContext';
import { required } from '../../shared/form/validators';
import logger from '../../../../utils/logger';
import { BillingInfo } from '../payment-details/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import { NotificationType, useNotificationContext } from '../../notifications/NotificationContext';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  button: {
    maxWidth: '150px',
    flexGrow: 1,
  },
});

const FinancialAssistance: React.FC<{ nextAction?: () => Promise<void>; skipAction?: () => Promise<void> }> = ({
  nextAction,
  skipAction,
}) => {
  const { fetchHelper, patient } = usePortalContext();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { notify } = useNotificationContext();

  const onSkip = async () => {
    if (skipAction) {
      await skipAction();
    }
  };

  const submitForm = async (data: Record<string, any>) => {
    setSubmitting(true);

    try {
      await fetchHelper(`/form/submit/${patient.orderId}`, {
        method: 'POST',
        body: {
          formType: NEURO_FINANCIAL_ASSISTANCE_FORM_KEY,
          patientId: patient.patientId,
          orderId: patient.orderId,
          data: {
            grossAnnualHouseholdIncome: data.grossAnnualHouseholdIncome.replace(/\$/g, '').replace(/,/g, ''),
            numberOfFamilyMembersInHousehold: data.numberOfFamilyMembersInHousehold,
          },
        },
      });

      if (nextAction) {
        await nextAction();
      }
    } catch (e: any) {
      logger.error(e, 'Error submitting financial assistance form');
      notify(NotificationType.ERROR, 'Error submitting financial assistance form, please try again');
    }

    setSubmitting(false);
  };

  const fetchFAStatus = async () => {
    try {
      const response = await fetchHelper<BillingInfo>(`/billing-info/${patient.orderId}`);
      if (response.financialAssistanceStatus) {
        // skip FA if FA status is already set
        if (nextAction) {
          await nextAction();
        }
      }
    } catch (e: any) {
      logger.error(e, 'Error fetching billing info');
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchFAStatus();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container direction="column" flexGrow={1} gap="16px">
      <ScreenHeader text={'Financial Assistance'} />
      <NeuroFinancialAssistanceContent />
      <Form
        onSubmit={submitForm}
        render={({ handleSubmit, valid }) => (
          <>
            <FormTextInput
              name="grossAnnualHouseholdIncome"
              label="Annual Household Income"
              validate={required}
              mask={{ numericOnly: true, prefix: '$', numeralThousandsGroupStyle: 'thousand', numeral: true }}
              data-testid="annual-household-income"
            />
            <FormSingleSelectDropdown
              name="numberOfFamilyMembersInHousehold"
              label="Number of Persons in Household"
              validate={required}
              options={getNeuroNumberFamilyMembersDropdownOptions()}
              data-testid="number-of-family-members"
            />
            <Grid
              item
              container
              flexGrow={1}
              padding="30px 0px"
              gap="15px"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Button
                ariaLabel={'Skip'}
                onClick={onSkip}
                buttonType="tertiary"
                disabled={submitting}
                data-testid="skip-button"
                classes={{
                  root: css(styles.button),
                }}
              >
                <Typography variant="button">Skip</Typography>
              </Button>
              <Button
                ariaLabel={'Continue'}
                onClick={handleSubmit}
                loading={submitting}
                disabled={!valid}
                data-testid="continue-button"
                classes={{
                  root: css(styles.button),
                }}
              >
                <Typography variant="button">Continue</Typography>
              </Button>
            </Grid>
          </>
        )}
      />
    </Grid>
  );
};

export default withPortalContainer(FinancialAssistance);

export const OnboardingFinancialAssistance: React.FC<{ next?: () => Promise<void>; skip?: () => Promise<void> }> = ({
  next,
  skip,
}) => <FinancialAssistance nextAction={next} skipAction={skip} />;
