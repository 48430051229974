export enum LoadingStatus {
  PENDING = 'pending',
  LOADED = 'loaded',
  FAILED = 'failed',
}

export type Pending = {
  status: LoadingStatus.PENDING;
};

export type Failed<E> = {
  status: LoadingStatus.FAILED;
  error: E;
};

export type Loaded<T> = {
  status: LoadingStatus.LOADED;
  result: T;
};

export type LoadingState<T, E = void> = Pending | Failed<E> | Loaded<T>;
