import React from 'react';
import { Field } from 'react-final-form';

interface ConditionProps {
  when: string;
  is: unknown;
  children: React.ReactNode;
}

const Condition: React.FC<ConditionProps> = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export default Condition;
