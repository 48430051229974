import { Grid, Modal, Typography } from '@mui/material';
import { Button } from '@tcl-boron-prefabs/button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'white',
  borderRadius: '8px',
  padding: '32px',
};

type PaymentProcessingModalProps = {
  open: boolean;
  onClose: () => void;
};

const PaymentProcessingModal: React.FC<PaymentProcessingModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Grid container direction="column" gap="20px" padding="30px" sx={style} data-testid="payment-notice">
        <Typography variant="h1">Payment Processing Notice</Typography>
        <Typography variant="body1">
          It may take a few days for your order to be marked as paid in the app. If you have any questions, please email{' '}
          <a href="mailto:help@tempus.com">help@tempus.com</a> or call (312) 598-9961.
        </Typography>
        <Button
          buttonType="primary"
          minWidth="150px"
          ariaLabel="Proceed to payment"
          onClick={onClose}
          data-testid="close-button"
        >
          I understand
        </Button>
      </Grid>
    </Modal>
  );
};

export default PaymentProcessingModal;
