import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Loader from '@tcl-boron-prefabs/loader';
import { LoaderProps } from '@tcl-boron-prefabs/loader/lib/types';

export const LOADER_TEST_ID = 'loader-test-id';

const LoadingIndicator: React.FunctionComponent<LoaderProps> = (props) => {
  return (
    <div className={css(styles.container)}>
      <Loader data-testid={LOADER_TEST_ID} {...props} />
    </div>
  );
};

export default LoadingIndicator;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
  },
});
