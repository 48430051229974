import React, { Fragment, FunctionComponent, RefObject, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/pdf.worker.entry';
import { css, StyleSheet } from 'aphrodite';
import { useEffect } from 'react';
import { grayPalette } from '@tcl-argon-colors/colors';
import { createRef } from 'react';
import LoadingIndicator from '../../../app/components/commons/LoadingIndicator';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { Breakpoints } from '../../../constants/styles';
import _ from 'lodash';

declare global {
  interface Window {
    pdfWorker: string;
  }
  interface Navigator {
    msSaveBlob: (blob: Blob, defaultName?: string) => boolean;
  }
}

type OnDocumentLoadSuccessPDF = {
  numPages: number;
  [key: string]: unknown;
};

type PDFViewerProps = {
  containerRef: RefObject<HTMLDivElement>;
  pdf: Blob;
};

pdfjs.GlobalWorkerOptions.workerSrc = window.pdfWorker;

const PDFViewer: FunctionComponent<PDFViewerProps> = (props) => {
  const [numPages, setNumPages] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const canvasRefs = useMemo<RefObject<HTMLCanvasElement>[]>(() => [], []);

  const paddingContainer = createRef<HTMLDivElement>();
  const scrollContainer = createRef<HTMLDivElement>();

  const container = props.containerRef;

  const resizePdf = useCallback(
    _.debounce(() => {
      const container = props.containerRef;
      if (container.current && paddingContainer.current && scrollContainer.current) {
        const fullContainerWidth = container.current.offsetWidth;
        const fullContainerHeight = container.current.offsetHeight;

        // set scroll container with a set width and height
        scrollContainer.current.style.width = `${fullContainerWidth}px`;
        scrollContainer.current.style.height = `${fullContainerHeight}px`;

        // calculate padding around container
        const paddingContainerStyles = window
          .getComputedStyle(paddingContainer.current)
          .getPropertyValue('padding-left');
        const padding = parseFloat(paddingContainerStyles ? paddingContainerStyles : '0');
        const containerWidth = fullContainerWidth - padding * 2;

        // render canvas
        canvasRefs.map((canvas) => {
          if (canvas.current) {
            const originalWidth = parseInt(canvas.current.style.width);
            const originalHeight = parseInt(canvas.current.style.height);
            const scale = containerWidth / originalWidth;
            const newWidth = `${containerWidth}px`;
            const newHeight = `${scale * originalHeight}px`;
            canvas.current.style.width = newWidth;
            canvas.current.style.height = newHeight;
          }
        });
      }
    }, 100),
    [container, paddingContainer, canvasRefs],
  );

  useEffect(() => {
    resizePdf();
    window.addEventListener('resize', resizePdf);

    return () => {
      window.removeEventListener('resize', resizePdf);
    };
  }, [resizePdf]);

  const onDocumentLoadSuccess = (pdf: OnDocumentLoadSuccessPDF) => {
    setNumPages(pdf.numPages);
  };

  return (
    <Fragment>
      {loading ? (
        <div className={css(styles.loadingContainer)}>
          <LoadingIndicator scale={1} />
        </div>
      ) : null}
      <div className={css(styles.scrollContainer)} ref={scrollContainer}>
        <div className={css(styles.container)} ref={paddingContainer}>
          <Document file={props.pdf} loading={() => <React.Fragment />} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (value, index) => {
              let canvas;
              if (canvasRefs[index]) {
                canvas = canvasRefs[index];
              } else {
                canvas = createRef<HTMLCanvasElement>();
                canvasRefs.push(canvas);
              }

              return (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  canvasRef={canvas}
                  onLoadSuccess={() => {
                    resizePdf();
                    setLoading(false);
                  }}
                />
              );
            })}
          </Document>
        </div>
      </div>
    </Fragment>
  );
};

export default PDFViewer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: grayPalette.gray20,
    padding: '1.429em 2.143em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      padding: '1.429em 0.714em',
      paddingBottom: '2.143em',
    },
  },
  loadingContainer: {
    backgroundColor: grayPalette.gray20,
    display: 'flex',
    flex: 1,
    postion: 'relative',
    zIndex: 10,
  },
  scrollContainer: {
    position: 'absolute',
    overflow: 'scroll',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      overflow: 'visible',
    },
  },
});
