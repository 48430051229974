import Grid from '@mui/material/Grid';
import { Button } from '@tcl-boron-prefabs/button';
import usePortalContext from '../../context/usePortalContext';
import { NeuroConsentToTestContentPart1, NeuroConsentToTestContentPart2 } from '@tempus/patient-forms-service-shared';
import withPortalContainer from '../../hooks/withPortalContainer';
import { Typography } from '@mui/material';
import { ConsentForm } from './components/ConsentForm';
import ScreenHeader from '../../components/ScreenHeader';

const ConsentToTest: React.FC<{ actionOverride?: () => Promise<void> }> = ({ actionOverride }) => {
  const { isFormEditOpen, setIsFormEditOpen } = usePortalContext();

  return (
    <>
      <ScreenHeader text="Consent to Testing" />
      {isFormEditOpen ? (
        <Grid item flexGrow={1}>
          <ConsentForm actionOverride={actionOverride} />
        </Grid>
      ) : (
        <Grid container direction="column" justifyContent="center">
          <NeuroConsentToTestContentPart1 />
          <NeuroConsentToTestContentPart2 />
          <Grid item container padding="30px 0px" justifyContent="center">
            <Button minWidth="150px" ariaLabel={'Next'} onClick={() => setIsFormEditOpen(true)}>
              <Typography variant="button">Next</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withPortalContainer(ConsentToTest);

export const OnboardingConsent: React.FC<{ next?: () => Promise<void> }> = ({ next }) => (
  <ConsentToTest actionOverride={next} />
);
