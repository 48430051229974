export enum NavigationPath {
  RESET_PASSWORD = '/resetPassword',
  REGISTER = '/register',
  TERMS = '/terms',
  FAQ = '/faq',
  VERIFY = '/verify',
  CONSENT = '/consent',
  CONSENT_RECEIPT = '/consent-receipt',
  ACCOUNT = '/account',
  PCP = '/pcp',
  RESULT = '/result',
  ORDERS = '/orders',

  KIT_RETURN = '/kit_return',
  PAYMENT_DETAILS = '/payment_details',
  PORTAL = '/',
  TEMPUS_PRO = '/pro',
  REPORT = '/report',
  LOGOUT = '/logout',
  TERMS_OF_USE = '/terms_of_use',
  REVIEW_PATIENT_INFO = '/review_patient_info',
  ONBOARDING = '/onboarding',
}
export enum AuthenticationState {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  VERIFIED = 'VERIFIED',
  AUTH0_AUTHENTICATED = 'AUTH0_AUTHENTICATED',
}
export enum OktaAuthNavigationPath {
  LOGIN = '/',
  CALLBACK = '/callback',
}
