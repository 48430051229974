import { Grid, Typography } from '@mui/material';
import { Information } from '@tcl-boron-icons/icons';
import { Card } from '@tcl-boron-prefabs/card';
import { css, StyleSheet } from 'aphrodite';

const PaymentConfirmationCard: React.FC = () => {
  return (
    <Card classes={{ root: css(PaymentConfirmationStyles.card) }} data-testid="payment-confirmation-card">
      <Grid container gap="8px" padding="8px" flexWrap={'nowrap'}>
        <Information height="20px" />
        <Typography variant="body1">
          After reviewing your benefits, it appears that using <b>insurance may result in higher out-of-pocket costs</b>{' '}
          than our self-pay option.
        </Typography>
      </Grid>
    </Card>
  );
};

const PaymentConfirmationStyles = StyleSheet.create({
  card: {
    marginTop: '8px',
    padding: '8px',
    height: 'auto',
    overflow: 'visible',
  },
});

export default PaymentConfirmationCard;
