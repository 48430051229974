import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { FieldValidator } from 'final-form';
import moment from 'moment';
import { ComponentProps } from 'react';
import { Field } from 'react-final-form';

interface FormSingleDatePickerProps
  extends Omit<ComponentProps<typeof SingleDatePicker>, 'value' | 'onChange' | 'onBlur' | 'status' | 'subContent'> {
  name: string;
  validate?: FieldValidator<string>;
}

const validFormats = ['MM/DD/YYYY', 'YYYY-MM-DD']; // Date picker uses the first format, OPUS stores as the second

const FormSingleDatePicker: React.FC<FormSingleDatePickerProps> = ({ name, validate, ...datePickerProps }) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <SingleDatePicker
          {...datePickerProps}
          value={{
            dateString: moment(input.value, validFormats, true).isValid()
              ? moment(input.value).format('MM/DD/YYYY')
              : input.value,
          }}
          onChange={(date) => input.onChange(date.dateString)}
          onBlur={input.onBlur}
          status={meta.error && meta.touched ? 'error' : 'default'}
          subContent={meta.error && meta.touched ? meta.error : null}
        />
      )}
    </Field>
  );
};

export default FormSingleDatePicker;
