import { useEffect, useState } from 'react';
import PortalContext, { IPortalContext, PortalPatient, initialPortalContext } from './PortalContext';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import fetchHelper, { RequestOptions } from '../../utils';
import config from '../../../config';
import { AuthenticationType } from '../../api/AuthenticationType';
import logger from '../../../utils/logger';
import { Grid, Typography } from '@mui/material';
import Loader from '@tcl-boron-prefabs/loader';
import ErrorScreen from '../components/ErrorScreen';
import OnboardingInfo from './interfaces/onboarding-status';
import { useHistory } from 'react-router-dom';
import { OnboardingStatus } from '@tempus/patient-web-service-shared';

const PortalContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [patient, setPatient] = useState<PortalPatient | undefined>();
  const [onboardingInfo, setOnboardingInfo] = useState<OnboardingInfo | undefined>();
  const [isFormEditOpen, setIsFormEditOpen] = useState(false);
  const { isLoading: isAuthLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { push } = useHistory();

  const fetchHelperOverride = async <T extends unknown>(path: string, options: RequestOptions = {}): Promise<T> =>
    fetchHelper<T>(path, {
      baseUrl: config.patientWebServiceBaseUrl,
      authenticationType: AuthenticationType.AUTH0,
      authToken: await getAccessTokenSilently(),
      headerOverrides: {
        'X-Selected-Portal-View': initialPortalContext.selectedPortalView,
      },
      ...options,
    });

  const fetchContext = async () => {
    try {
      const response = await fetchHelperOverride<PortalPatient>('/orders');
      setPatient(response);
    } catch (e: any) {
      logger.error(e, 'Error fetching patient portal context');
      setError(true);
    }
  };

  const fetchOnboardingInfo = async () => {
    try {
      const response = await fetchHelperOverride<OnboardingInfo>('/onboarding/status');
      setOnboardingInfo(response);

      if (response.status !== OnboardingStatus.COMPLETE) {
        push('/onboarding');
      }
    } catch (e: any) {
      logger.error(e, 'Error fetching onboarding info');
      setError(true);
    }
  };

  const fetchData = async () => {
    await fetchContext();
    await fetchOnboardingInfo();
    setLoading(false);
  };

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated) {
      fetchData();
    }
  }, [isAuthLoading, isAuthenticated]);

  const state: IPortalContext = {
    ...initialPortalContext,
    loading,
    error,
    ...(patient && { patient }),
    ...(onboardingInfo && { onboardingInfo }),
    setOnboardingInfo,
    isFormEditOpen,
    setIsFormEditOpen,
    fetchHelper: fetchHelperOverride,
  };

  if (loading) {
    return (
      <Grid container direction="column" gap="8px" alignItems="center" justifyContent="center" height="100%">
        <Loader size="large" />
        <Typography variant="body1">Loading</Typography>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container alignItems="center" justifyContent="center" height="100%">
        <ErrorScreen />
      </Grid>
    );
  }

  return <PortalContext.Provider value={state}>{children}</PortalContext.Provider>;
};

export default withAuthenticationRequired(PortalContextProvider);
