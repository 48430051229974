import { Grid, Typography } from '@mui/material';
import { Card } from '@tcl-boron-prefabs/card';
import { DEFAULT_PAYMENT_TEXT, SelfPayConfigType } from '../constants';
import { Button } from '@tcl-boron-prefabs/button';
import { CALL_LINK, SMS_LINK } from '../../../shared/constants';

type FinancialAssistanceCardProps = {
  paymentConfig: SelfPayConfigType;
  setPaymentNoticeOpen: (open: boolean) => void;
  paid?: boolean;
};

export const FinancialAssistanceCard: React.FC<FinancialAssistanceCardProps> = ({
  paymentConfig,
  setPaymentNoticeOpen,
  paid,
}) => {
  const {
    faCardStatusText,
    faCardContactButtons,
    faCardPaymentText,
    icon: Icon,
    iconColor,
    amount,
    approvalText,
  } = paymentConfig;

  return (
    <Card style={{ height: 'auto', padding: '16px' }}>
      <Grid container direction="column" gap="16px">
        <Grid item container alignItems="center" gap="8px">
          {Icon ? <Icon color={iconColor} /> : null}
          <Typography variant="h1">Financial Assistance: {faCardStatusText}</Typography>
        </Grid>
        {approvalText.map((text, index) => (
          <Typography key={index} variant="body1">
            {text}
          </Typography>
        ))}
        {paid ? (
          <Typography variant="body1" fontWeight={600} letterSpacing="2px">
            PAID: ${amount}
          </Typography>
        ) : amount !== undefined ? (
          <>
            {amount > 0 ? <Typography variant="body1">{faCardPaymentText ?? DEFAULT_PAYMENT_TEXT}</Typography> : null}
            <Typography variant="body1" fontWeight={600} letterSpacing="2px">
              AMOUNT YOU OWE: ${amount}
            </Typography>
            {amount > 0 ? (
              <Grid item container justifyContent="center" alignSelf="center">
                <a href={paymentConfig.link} target="_blank" rel="noreferrer">
                  <Button
                    minWidth="150px"
                    ariaLabel={'Pay Now'}
                    buttonType="secondary"
                    style={{ alignSelf: 'center' }}
                    data-testid="pay-now-button"
                    onClick={() => setPaymentNoticeOpen(true)}
                  >
                    <Typography variant="button">Pay Now</Typography>
                  </Button>
                </a>
              </Grid>
            ) : null}
          </>
        ) : null}
        {faCardContactButtons ? (
          <Grid item container justifyContent="space-around" alignSelf="center" wrap="nowrap">
            <a href={CALL_LINK} target="_blank" rel="noreferrer">
              <Button
                minWidth="100px"
                ariaLabel="Call"
                buttonType="secondary"
                style={{ alignSelf: 'center' }}
                data-testid="call-button"
                small
              >
                <Typography variant="button">Call</Typography>
              </Button>
            </a>
            <a href={SMS_LINK} target="_blank" rel="noreferrer">
              <Button
                minWidth="100px"
                ariaLabel="Text"
                buttonType="secondary"
                style={{ alignSelf: 'center' }}
                data-testid="text-button"
                small
              >
                <Typography variant="button">Text</Typography>
              </Button>
            </a>
          </Grid>
        ) : null}
      </Grid>
    </Card>
  );
};
