import { PortalContainer, PortalContainerProps } from '../components/PortalContainer';

const withPortalContainer = (Component: React.ComponentType, portalContainerProps?: PortalContainerProps) => {
  const component = (props: any) => {
    return (
      <PortalContainer {...portalContainerProps}>
        <Component {...props} />
      </PortalContainer>
    );
  };

  return component;
};

export default withPortalContainer;
