import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Button } from '@tcl-boron-prefabs/button';

type FormSignatureCanvasProps = Pick<FieldRenderProps<any>['input'], 'onChange'>
  & Pick<FieldRenderProps<any>, 'meta'>
  & {
    parent: React.RefObject<HTMLDivElement>;
    onClear: () => void;
  };

const FormSignatureCanvas: React.FunctionComponent<FormSignatureCanvasProps> = ({ onChange, onClear, meta, parent }) => {
  const signatureRef = React.useRef<any>();

  useEffect(() => {
    if (!meta.visited) {
      signatureRef.current.clear();
    }
  }, [meta.visited]);

  useEffect(() => {
    if (meta.submitting) {
      signatureRef.current.off();
    } else {
      signatureRef.current.on();
    }
  }, [meta.submitting]);

  const [canvasProps, setCanvasProps] = React.useState({ width: 0, height: 0 });

  useEffect(() => {
    const parentElement = parent.current;
    if (parentElement) {
      const updateCanvasDimensions = () => {
        const width = parentElement.clientWidth;
        if (width < 600) {
          setCanvasProps({ width, height: 150 });
        } else if (width < 900) {
          setCanvasProps({ width, height: 200 });
        } else {
          setCanvasProps({ width, height: 250 });
        }
      };

      updateCanvasDimensions();
      window.addEventListener('resize', updateCanvasDimensions);

      return () => {
        window.removeEventListener('resize', updateCanvasDimensions);
      };
    }
  }, []);

  const clear = () => {
    onClear();
    signatureRef.current?.clear();
  }

  return (
    <>
      <ReactSignatureCanvas
        ref={signatureRef}
        canvasProps={canvasProps}
        onEnd={() => {
          onChange(signatureRef.current?.toDataURL());
        }}
      />
      <Button ariaLabel="clear" buttonType="tertiary" type="reset" onClick={clear} small>
        Clear
      </Button>
    </>
  );
};

export default FormSignatureCanvas;
