import { Box, Grid } from '@mui/material';
import { PatientInfo } from './shared';
import usePortalContext from '../../context/usePortalContext';
import { Form } from 'react-final-form';
import FormTextInput from '../../shared/form/FormTextInput';
import { email, optional, phoneNumber, postalCode, required } from '../../shared/form/validators';
import FormSingleDatePicker from '../../shared/form/FormSingleDatePicker';
import FormSingleSelectDropdown from '../../shared/form/FormSingleSelectDropdown';
import { Button } from '@tcl-boron-prefabs/button';
import { css, StyleSheet } from 'aphrodite';
import fetchHelper from '../../../utils';
import { useState } from 'react';
import { STATES_AND_TERRITORIES } from '../../../constants/statesAndTerritories';
import _ from 'lodash';

require('cleave.js/dist/addons/cleave-phone.us');

interface EditPatientInfoFormProps {
  info: PatientInfo;
  fetchAndSetState: (...args: Parameters<typeof fetchHelper>) => Promise<void>;
}

const EditPatientInfoForm: React.FC<EditPatientInfoFormProps> = ({
  info,
  fetchAndSetState,
}: EditPatientInfoFormProps) => {
  const { setIsFormEditOpen } = usePortalContext();
  const [isSubmitting, modifyIsSubmitting] = useState(false);

  const putPatientInfo = async (info: PatientInfo) => {
    const body = {
      ...info,
      email: info.email,
      phoneNumbers: undefined,
      phoneNumber: info.phoneNumbers?.at(0)?.number,
    };
    return fetchAndSetState(`/patient-info`, { method: 'PUT', body });
  };

  const submit = async (data: PatientInfo) => {
    modifyIsSubmitting(() => true);
    await putPatientInfo(data);
    modifyIsSubmitting(() => false);
    setIsFormEditOpen(false);
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={info}
      render={({ handleSubmit, invalid }) => (
        <Grid container direction="column" gap="15px">
          <FormTextInput label="First Name*" name="name.first" validate={required} disabled />
          <FormTextInput label="Last Name*" name="name.last" validate={required} disabled />
          <FormTextInput label="Street Address*" name="address.street1" validate={required} />
          <FormTextInput label="Apt / Unit #" name="address.street2" />
          <FormTextInput label="City*" name="address.city" validate={required} />
          <FormSingleSelectDropdown
            label="State*"
            name="address.state"
            options={STATES_AND_TERRITORIES}
            validate={required}
          />
          <FormTextInput label="Zipcode*" name="address.postalCode" validate={postalCode} />
          <FormSingleDatePicker label="Date of Birth*" name="dateOfBirth" validate={required} disabled />
          <FormTextInput
            label="Phone"
            name="phoneNumbers.0.number"
            mask={{ phone: true }}
            validate={optional(phoneNumber)}
          />
          <FormTextInput label="Email" name="email" validate={optional(email)} />
          <Box className={css(EditPatientInfoFormStyles.submitFooter)}>
            <Button
              className={css(EditPatientInfoFormStyles.submitButton)}
              ariaLabel="submit"
              type="submit"
              onClick={handleSubmit}
              disabled={invalid}
              loading={isSubmitting}
            >
              Save and continue
            </Button>
          </Box>
        </Grid>
      )}
      validate={(values) => {
        if (_.isNil(values.email) && _.isNil(values.phoneNumbers?.at(0)?.number)) {
          return {
            email: 'At least one contact method (phone, email) is required',
            phoneNumbers: [{ number: 'At least one contact method (phone, email) is required' }],
          };
        }
      }}
    />
  );
};

export default EditPatientInfoForm;

const EditPatientInfoFormStyles = StyleSheet.create({
  submitFooter: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    paddingTop: '30px',
    paddingBottom: '30px',
    backgroundColor: 'white',
    width: '100%',
  },
  submitButton: {
    display: 'block',
    margin: 'auto',
  },
});
