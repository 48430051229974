import { TextInput } from '@tcl-boron-prefabs/text-input';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { ComponentProps } from 'react';

interface FormTextInputProps
  extends Omit<ComponentProps<typeof TextInput>, 'value' | 'onChange' | 'onBlur' | 'name' | 'status' | 'subContent'> {
  name: string;
  validate?: FieldValidator<string>;
}

const FormTextInput: React.FC<FormTextInputProps> = (props) => {
  const { name, validate, ...inputProps } = props;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <TextInput
          {...inputProps}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          name={input.name}
          status={meta.error && meta.touched ? 'error' : 'default'}
          subContent={meta.error && meta.touched ? meta.error : null}
        />
      )}
    </Field>
  );
};

export default FormTextInput;
